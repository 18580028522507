import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { StoreContext } from 'index';
import ClipLoader from 'react-spinners/ClipLoader';
import { toJS } from 'mobx';

const override = css`
  display: block;
  opacity: 1;
`;

function Button({ label, className, type = 'button', onClick, isDisabled = false, name }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { isLoading } = userStore;

  return (
    <button
      onClick={type === 'button' ? onClick : () => {}}
      name={name}
      type={type}
      className={'d-row justify-center ' + className}
      disabled={isLoading || isDisabled}>
      {isLoading && (
        <ClipLoader
          color={className?.search('btn-outline' ? '#393686' : '#fff')}
          loading={true}
          css={override}
          size={15}
        />
      )}

      <small className={`${isLoading && 'ml-2'} text-xs font-inter-regular`}>{label}</small>
    </button>
  );
}
export default Button;
