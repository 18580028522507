import React, { useEffect } from 'react';
import { Sales } from 'assets/img';
import { useHistory } from 'react-router-dom';

function BoostSales() {
  const history = useHistory();

  return (
    <div className="h-100 mt-2 w-full bg-light-gray flex flex-col md:flex-row items-center justify-between py-8 px-8 mb-10">
      <div className="d-row">
        <img src={Sales} className="object-contain h-8 w-8 pr-2" />

        <div className="d-col">
          <small className="text-base	font-inter-regular text-black">Boost your sales!</small>

          <small className="text-xs font-inter-regular text-black">
            Let’s discuss how we can help you increase your catering sales
          </small>
        </div>
      </div>

      <div className="mt-5 md:mt-0 flex flex-col md:flex-row">
        <button
          className="btn-outline-pink w-full md:w-32 mr-2"
          onClick={() => {
            history.push('/sales');
          }}>
          Learn more
        </button>

        <a
          className="btn-pink mt-2 md:mt-0"
          href={process.env.REACT_APP_CALENDLY_LINK_FOR_RESTO_SIGNUP}
          target="_blank"
          rel="noopener noreferrer">
          Schedule a call
        </a>
      </div>
    </div>
  );
}

export default BoostSales;
