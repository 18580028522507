import React, { useState, useContext, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import Screen from 'utils/helpers/ScreenSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Header/Dropdown';
import Notifications from 'components/Header/Notifications';
import { Loader } from 'components/Shared';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import LocalStorage from 'utils/helpers/localStorage';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Logo, SalesArrow } from '../../assets/img';
import { useNotificationMethods } from 'hooks/useNotificationMethods';
import { useSideBarOptions } from 'config/sideBarOptions';

function SideBar({ fixed, routes }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { unreadNotifications } = useNotificationMethods();
  const { isLoading, currentUser, defaultRestaurant } = userStore;
  const { getSideBarTabs } = useSideBarOptions();

  const buttonPress = () => {
    setMenuOpen(!menuOpen);
    const sidebar = document.querySelector('.sidebar');
    sidebar.classList.toggle('-translate-x-full');
  };
  const location = useLocation();

  useEffect(() => {
    unreadNotifications(defaultRestaurant?.value);
  }, []);

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/order-summary':
        return 'Daily Order Summary';
      case '/profile':
        return 'Profile';
      case '/team-management':
        return 'Team Management';
      case '/restaurant-profile':
        return 'Restaurant Profile';
      case '/accounting':
        return 'Accounting';
      case '/menu':
        return 'Menu';
      case '/schedule':
        return 'Schedule';
      case '/sales':
        return 'Sales';
      default:
        return 'Daily Order Summary';
    }
  };

  const submitSignOut = e => {
    e.preventDefault();
    store.userStore.setLoader(true);
    setTimeout(() => {
      LocalStorage.removeKey('auth_token');
      store.userStore.clearStoredData();
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <div className="flex">
        {isLoading && !menuOpen && (
          <div className="modal-overlay absolute w-full z-20 h-full bg-transparent">
            <div
              className="relative opacity-100 w-full h-full bg-transparent"
              style={{ opacity: 0.8 }}>
              <Loader />
            </div>
          </div>
        )}

        <div className="bg-white text-gray-800 flex items-center justify-between md:hidden fixed top-0 left-0 z-20 shadow-lg w-full">
          <button
            className="mobile-menu-button pl-4 py-4 focus:outline-none focus:bg-gray-100 w-1/3"
            onClick={() => {
              buttonPress();
            }}>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>

          <small className="text-center font-inter-regular w-1/2">{getPageTitle()}</small>

          <div className="w-1/2 items-end flex justify-end">
            <div className="d-row">
              <Notifications />

              <Screen.Desktop>
                <Dropdown />
              </Screen.Desktop>
            </div>
          </div>
        </div>

        <aside className="h-screen sticky top-0 z-10 shadow">
          <div
            className={`bg-white md:z-0 flex flex-col pt-16 lg:pt-10 pb-24 md:pb-0 overflow-y-auto border md:border-none sidebar text-blue-100 space-y-6 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out
        ${screenType.isTablet ? 'pr-8' : 'w-64'}
        `}>
            <nav>
              <Screen.Mobile>
                <Dropdown />
                <div className="h-0.5 my-6 bg-gray-300 mx-3" />
              </Screen.Mobile>

              {getSideBarTabs(defaultRestaurant?.role)?.routes?.map(
                ({ label, icon, url, tooltip, newTab, customIcon }, index) => (
                  <NavLink
                    key={index}
                    title={tooltip}
                    to={url}
                    onClick={() => {
                      url === '/order-summary' && store.userStore.setDefaultSummaryDate(new Date());
                      if (menuOpen) buttonPress();
                    }}
                    activeStyle={{ color: '#6D69D3' }}
                    isActive={() =>
                      (url === '/order-summary' ? [url, '/order'] : [url]).includes(
                        location.pathname
                      )
                    }
                    className=" py-4 px-3 rounded transition duration-200 hover:bg-table-row hover:text-gray-400 text-dim-black d-row items-center no-underline justify-between">
                    <div className="d-row">
                      <div className="w-6">
                        {customIcon ? (
                          <img src={SalesArrow} className="object-contain h-5 w-5" />
                        ) : (
                          <FontAwesomeIcon icon={icon} size="1x" />
                        )}
                      </div>

                      <small className="pl-1 lg:pl-3 font-inter-regular text-xxs lg:text-sm">
                        {label}
                      </small>
                    </div>

                    {newTab && location?.pathname !== url && (
                      <small className="bg-hh-accent-light text-white text-xs font-inter-regular rounded-lg px-2 py-1">
                        New
                      </small>
                    )}
                  </NavLink>
                )
              )}

              <Screen.Mobile>
                <NavLink
                  to="/profile"
                  onClick={() => {
                    store.userStore.setDefaultSummaryDate(new Date());
                    store.orderStore.setCurrentPath(null);
                    if (menuOpen) buttonPress();
                  }}
                  className=" py-4 px-3 rounded transition duration-200 hover:bg-table-row hover:text-black d-row items-center no-underline">
                  <div className="w-6 mr-1">
                    {(screenType.isDesktop || screenType.isMobile) && (
                      <FontAwesomeIcon
                        icon={faUser}
                        color={location.pathname == '/profile' ? '#6D69D3' : '#2B2A2A'}
                        size="1x"
                      />
                    )}
                  </div>

                  <small
                    className={`pl-1 lg:pl-3 font-inter-regular text-dim-black text-xxs lg:text-sm ${
                      location.pathname == '/profile' && 'text-light-purple'
                    }`}>
                    Profile
                  </small>
                </NavLink>

                <button
                  type="button"
                  onClick={e => submitSignOut(e)}
                  className={
                    'text-dim-black px-3 block w-full text-center py-2 text-lg font-inter-medium hover:text-gray-500'
                  }>
                  Logout
                </button>
              </Screen.Mobile>
            </nav>

            <Screen.Mobile>
              <div className="d-col items-center">
                <img src={Logo} className=" h-14 w-56 self-start object-contain " />
                <small className="text-xs text-dark-gray font-inter-regular">
                  hungerhub Canada Inc 2021
                </small>
              </div>
            </Screen.Mobile>
          </div>
        </aside>

        <main className="bg-main-background px-2 flex-1">
          {menuOpen && (
            <div className="fixed inset-0 transition-opacity pin">
              <div style={{ opacity: 0.5 }} className="modal-overlay absolute w-full h-full">
                {routes}
              </div>
            </div>
          )}

          {!menuOpen && <div className="md:mt-0 mt-16"> {routes} </div>}
        </main>
      </div>
    </>
  );
}

export default observer(SideBar);
