import React from 'react';
import { Link } from 'react-router-dom';
import { NotFound } from 'components/Shared';
import dateTime from 'utils/helpers/dateTime';
import Screen from 'utils/helpers/ScreenSize';
import { Tooltip } from 'components/Shared';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import array from 'utils/helpers/array';
import { isODCShipment, isHungerhubBusiness, isDirectCatering } from 'utils/helpers/shipment';

function Td({ children, to, className }) {
  const ContentTag = to ? Link : 'div';
  return (
    <td className={`py-3 ${className}`}>
      <ContentTag to={to}>{children}</ContentTag>
    </td>
  );
}

function ScheduleTable({ data, updateSchedule }) {
  const screenType = Screen.ScreenType();
  const { timeZone } = new useEmployeeId();
  const { openReportProblemChat } = useFreshChatMethods();
  const { uncateringShipmentRoute, cateringShipmentRoute } = useQueryMethods();

  const getStatus = shipment_acceptance_status => {
    return (
      <strong
        className={`font-inter-medium text-xs ${
          shipment_acceptance_status ? 'text-green-700' : 'text-red-700'
        }`}>
        {shipment_acceptance_status ? 'Confirmed' : 'Cancelled'}
      </strong>
    );
  };

  const update = (schedule, status) => {
    updateSchedule(
      schedule?.menu_id,
      schedule?.shipment_ids,
      status,
      moment(schedule?.meal_date).format('dddd, MMMM Do YYYY')
    );
  };

  const linkToPath = (schedule, cateringOrder) => {
    if (cateringOrder)
      return cateringShipmentRoute({
        source_of_business: schedule?.source_of_business,
        shipment_id: schedule?.shipment_ids[0],
      });
    else
      return schedule?.shipment_ids?.length > 1
        ? `/?date=${schedule?.meal_date}`
        : uncateringShipmentRoute({
            shipment_id: schedule?.shipment_ids[0],
          });
  };

  const getOrderDeadline = date => {
    return `${dateTime.formatDate(date, 'dddd, MMMM Do', timeZone)} ${dateTime.formatTime(
      date,
      timeZone
    )}`;
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full table-fixed">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="t-head text-left pl-0.5 md:pl-3">
                    <small className="t-row">Menu</small>
                  </th>

                  <th scope="col" className="t-head text-left pl-0.5 md:pl-3">
                    <small className="t-row">Order Type</small>
                  </th>

                  <th scope="col" className="t-head text-left pl-0.5 md:pl-3">
                    <div className="d-row">
                      <small className="t-row">Order Date</small>
                      <Tooltip
                        tooltipText="View order for estimated pick up time."
                        id="order-date"
                      />
                    </div>
                  </th>

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head">
                      <small className="t-row">Order Deadline</small>
                    </th>
                  )}

                  <th scope="col" className="t-head text-left" style={{ width: '10%' }}>
                    <small className="t-row">Destination</small>
                  </th>

                  <th scope="col" className="t-head" style={{ width: '12%' }}>
                    <div className="d-row">
                      <small className="t-row">Expected Bags</small>
                      <Tooltip
                        tooltipText="The estimated bag count is based on information from the client at the time of requesting the order. This information can change and we will update this number as we become aware of changes in circumstances. Please view the actual order page to see the actual number of bags. Note: More bags are added closer to the order deadline."
                        id="expected-bags"
                      />
                    </div>
                  </th>

                  <th scope="col" className="t-head text-center">
                    <div className="d-row justify-center">
                      <small className="t-row">Status</small>
                      <Tooltip
                        tooltipText="If you need to make changes to a confirmed order please start a chat."
                        id="schedule-status"
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {array.isPresent(data) &&
                  data?.map((schedule, ind) => (
                    <tr key={ind} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                      {schedule?.shipment_acceptance_status === null ||
                      schedule?.shipment_acceptance_status ? (
                        <Td
                          to={() => linkToPath(schedule, isODCShipment(schedule?.shipment_type))}
                          className="align-top text-left pl-0.5 md:pl-3">
                          <div className="d-row py-2">
                            {screenType.isTabletOrDesktop && (
                              <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                color={'#393838'}
                                size="xs"
                                className="mr-2 hover:text-blue-400"
                              />
                            )}

                            <p className="input-label text-dark-gray break-words capitalize hover:text-blue-400">
                              {schedule?.menu}
                            </p>
                          </div>
                        </Td>
                      ) : (
                        <td className="py-3 align-top break-words text-left pl-0.5 md:pl-3">
                          <small className="input-label text-dark-gray capitalize">
                            {schedule?.menu}
                          </small>
                        </td>
                      )}

                      <td className="py-3 align-top break-words text-left pl-0.5 md:pl-3">
                        <small
                          className={`input-label text-dark-gray ${
                            schedule?.shipment_type != 'odc' && 'capitalize'
                          }`}>
                          {isODCShipment(schedule?.shipment_type)
                            ? isHungerhubBusiness(schedule?.source_of_business)
                              ? 'hungerhub Catering'
                              : 'Direct Catering'
                            : schedule?.shipment_type}
                        </small>
                      </td>

                      <td className="py-3 align-top break-words text-left pl-0.5 md:pl-3">
                        <small className="text-xs text-dark-gray font-inter-regular capitalize">
                          {moment(schedule?.meal_date).format('dddd, MMMM Do')}
                        </small>
                      </td>

                      <td className="py-3 align-top break-words text-left pr-2">
                        <small className="text-xs text-dark-gray font-inter-regular">
                          {isODCShipment(schedule?.shipment_type) && (
                            <strong className="text-black">Estimated Pick Up: </strong>
                          )}

                          {schedule?.shipment_type !== 'odc'
                            ? getOrderDeadline(schedule?.cutoff_time)
                            : `${dateTime.subtractTime(
                                schedule.delivery_time,
                                45,
                                timeZone
                              )} - ${dateTime.subtractTime(schedule?.delivery_time, 15, timeZone)}`}
                        </small>
                      </td>

                      {schedule?.shipment_type !== 'odc' ? (
                        <td className="py-3 align-top break-words text-left " colSpan="2">
                          <table className="w-full table-fixed">
                            {array.isPresent(schedule?.shipment_details) &&
                              schedule?.shipment_details.map(shipment => (
                                <tr>
                                  <td className="align-top" style={{ width: '15%' }}>
                                    <small className="text-xs text-dark-gray font-inter-regular">
                                      {shipment?.destination_code}
                                    </small>
                                  </td>

                                  <td className="align-top text-left" style={{ width: '15%' }}>
                                    <small className="text-xs text-dark-gray text-left font-inter-regular">
                                      {shipment?.bags}
                                    </small>
                                  </td>
                                </tr>
                              ))}
                          </table>
                        </td>
                      ) : (
                        <>
                          <td
                            className="py-3 align-top break-words text-left "
                            style={{ width: '10%' }}>
                            <table className="w-full table-fixed">
                              {array.isPresent(schedule?.shipment_details) &&
                                schedule?.shipment_details.map(shipment => (
                                  <tr>
                                    <td className="align-top">
                                      <small className="text-xs text-dark-gray font-inter-regular">
                                        {shipment?.destination_code}
                                      </small>
                                    </td>
                                  </tr>
                                ))}
                            </table>
                          </td>

                          <td className="py-3 text-center">
                            {(schedule?.shipment_acceptance_status === null ||
                              schedule?.shipment_acceptance_status) && (
                              <Link
                                to={() => linkToPath(schedule, true)}
                                className="flex items-center justify-center btn-purple text-xs w-full break-normal">
                                View Order
                              </Link>
                            )}
                          </td>
                        </>
                      )}

                      {screenType.isTabletOrDesktop && (
                        <td className="py-3 break-words text-center">
                          {schedule?.shipment_acceptance_status === null ? (
                            <div className="d-col items-center">
                              <button
                                style={{ width: '100px' }}
                                onClick={e => {
                                  e.stopPropagation();

                                  update(schedule, true);
                                }}
                                className="btn-purple text-xxs w-full bg-green-500 break-normal">
                                Confirm
                              </button>

                              <button
                                style={{ width: '100px' }}
                                onClick={e => {
                                  e.stopPropagation();

                                  schedule?.shipment_type !== 'odc'
                                    ? openReportProblemChat()
                                    : isHungerhubBusiness(schedule?.source_of_business)
                                    ? openReportProblemChat()
                                    : update(schedule, false);
                                }}
                                className="btn-purple text-xxs px-0 w-full mt-1 bg-light-red break-normal">
                                {schedule?.shipment_type !== 'odc'
                                  ? 'Report Problem'
                                  : isHungerhubBusiness(schedule?.source_of_business)
                                  ? 'Report Problem'
                                  : 'Decline'}
                              </button>
                            </div>
                          ) : (
                            <div className="d-col">
                              {schedule?.shipment_cancelled_at && (
                                <span className="text-xxs mb-2">
                                  <strong>Cancelled at:</strong>{' '}
                                  {dateTime.formatDateWithTimzoneInfo(
                                    schedule?.shipment_cancelled_at,
                                    'dddd, MMMM Do YYYY HH:mm a',
                                    timeZone
                                  )}
                                </span>
                              )}

                              {getStatus(schedule?.shipment_acceptance_status)}
                            </div>
                          )}

                          {schedule?.shipment_acceptance_status &&
                            isODCShipment(schedule?.shipment_type) &&
                            isDirectCatering(schedule?.source_of_business) && (
                              <div className="d-row justify-center">
                                <button
                                  style={{ width: '100px' }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    update(schedule, false);
                                  }}
                                  className="btn-purple text-xxs bg-light-red break-normal">
                                  Decline
                                </button>
                              </div>
                            )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>

            <NotFound data={data} message="No Schedules found" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleTable;
