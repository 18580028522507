import moment from 'moment-timezone';

const formatTime = (time, timeZone) => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  let _time = moment(time).local()?.utcOffset(offset).format('h:mm A');
  return _time === 'Invalid date'
    ? `${time} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const subtractTime = (time, minutes, timeZone) => {
  let _time = moment(time, 'HH:mm a').subtract(minutes, 'minutes').format('hh:mm a');

  return _time === 'Invalid date'
    ? `${time} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const formatDate = (date, format, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return moment(date).local()?.utcOffset(offset).format(format);
};

const formatDateWithoutTz = (date, format) => {
  return moment(date).format(format);
};

const isToday = date => {
  return moment().isSame(moment(date).local(), 'day');
};

const current = () => {
  return formatDate(new Date(), 'YYYY-MM-DD');
};

export const checkAndGetTimezone = (timezone = 'America/New_York') => {
  return moment().tz(timezone)?.utcOffset() === moment()?.utcOffset()
    ? ''
    : `(${moment().tz(timezone).zoneAbbr()})`;
};

const onlyDate = date => {
  return date?.split('T')[0] || '';
};

export const isSameDay = date => {
  return moment(moment().format('l')).isSame(moment(date).format('l'));
};

export const ifSelectedTimeAfterCurrent = time => {
  return moment(moment().format('HH:mm'), 'HH:mm').isBefore(moment(time, 'HH:mm'));
};

const formatDateWithTimzoneInfo = (date, format, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return `${moment(date).local().utcOffset(offset).format(format)} - ${checkAndGetTimezone(
    timeZone
  )}`;
};

const formatDateWithTimezone = (date, format, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return moment(date).local().utcOffset(offset).format(format);
};

const checkEndTimeAfterStartTime = (start, end) => {
  const startTime = moment(start, 'HH:mm:ss');
  const endTime = moment(end, 'HH:mm:ss');

  if (endTime.isAfter(startTime)) {
    return true; // End time is after start time
  } else {
    return false; // End time is not after start time
  }
};

export const getTimeOptionsWithDisabledInDirecory = (selectedDate, options) => {
  return options
    ?.map(option => {
      if (
        moment(
          `${moment(selectedDate).format('YYYY-MM-DD')} ${option?.label}`,
          'YYYY-MM-DD h:mm a'
        ).isSameOrBefore(moment())
      )
        return { ...option, disabled: true };
      return option;
    })
    .filter(option => !option.disabled);
};

export const timeFormat = 'h:mm a';

const generateTimeOptions = () => {
  const options = [];
  const startTime = moment().startOf('day'); // Start from midnight

  while (startTime.isBefore(moment().endOf('day'))) {
    const timeString = startTime.format('h:mm A');
    options.push({ value: timeString, label: timeString });
    startTime.add(30, 'minutes'); // Increment by 30 minutes
  }

  return options;
};

const to24HourFormat = timeString => {
  return moment(timeString, 'h:mm A').format('HH:mm:ss');
};

const to12HourFormat = timeString => {
  return moment(timeString, 'HH:mm:ss').format('h:mm A');
};

const splitDate = dateStr => {
  return dateStr?.split('T')[0];
};

export const default12amTime = '00:00:00';

export const fullDateFormat = 'YYYY-MM-DD';

export const defaultStartTime = '07:00:00';

export const defaultEndTime = '19:00:00';

export default {
  formatTime,
  subtractTime,
  checkAndGetTimezone,
  formatDate,
  isToday,
  onlyDate,
  current,
  formatDateWithoutTz,
  isSameDay,
  ifSelectedTimeAfterCurrent,
  formatDateWithTimzoneInfo,
  checkEndTimeAfterStartTime,
  getTimeOptionsWithDisabledInDirecory,
  generateTimeOptions,
  to24HourFormat,
  to12HourFormat,
  splitDate,
  formatDateWithTimezone,
};
