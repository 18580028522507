import qs from 'qs';
import api from '../store/api';
import { makeObservable, observable, action } from 'mobx';
import { client } from '../store';
import { errorHandler } from 'utils/middlewares/errorHandler';

class MenuStore {
  menus = [];
  optionCategories = [];
  menuTypes = [];
  defaultMenuType = null;
  menuCategories = [];
  menu = null;

  constructor() {
    // makePersistable(this, {
    //   name: 'MenuStore',
    //   properties: ['defaultDestinationCode', 'destinationCodes'],
    //   storage: window.localStorage,
    // });

    makeObservable(this, {
      menus: observable,
      optionCategories: observable,
      menuTypes: observable,
      menuCategories: observable,
      getOptionCategories: action,
      getMenus: action,
      clearOptionCategories: action,
      defaultMenuType: observable,
      updateDefaultMenu: action,
      menu: observable,
    });
  }

  async getMenus(restaurant_id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus(restaurant_id)}&${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.menus = data?.data?.data;
          this.menuTypes = data?.data?.data?.map(menu => ({
            label: menu?.name,
            value: menu.id,
            menu: menu?.attributes,
          }));

          this.defaultMenuType = this.menuTypes[0];
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async getMenuDetails(restaurantId, menuId) {
    return client()
      .get(`${api.menu(menuId, restaurantId)}`)
      .then(
        action('fetchSuccess', data => {
          this.menu = data?.data?.data;
          this.pluckAndSetCategories(data?.data?.data);
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  updateDefaultMenu(selected, restaurantId) {
    this.menu = null;
    this.defaultMenuType = selected;
    this.menuCategories = [];
    if (restaurantId) return this.getMenuDetails(restaurantId, selected.value);
  }

  pluckAndSetCategories(menu) {
    let _categories = menu?.attributes?.menu_categories.map(
      ({ attributes: { id, name, display_name, display_name_with_html } }) => ({
        id,
        name,
        display_name,
        display_name_with_html,
      })
    );

    this.menuCategories = _categories;
  }

  async getOptionCategories(menuCategoryId, restaurant_id) {
    return client()
      .get(`${api.optionCategories(menuCategoryId, restaurant_id)}`)
      .then(
        action('fetchSuccess', data => {
          this.optionCategories = data;
          return data;
        }),
        action('fetchError', error => {
          return error;
        })
      );
  }

  async updateHoursOfOperation(menuId, restaurantId, payload) {
    return client()
      .put(`${api.menuWithId(menuId, restaurantId)}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getMenuDetails(restaurantId, menuId);
          return data;
        }),
        action('fetchError', error => {
          errorHandler(
            error.response,
            null,
            'The following orders are currently in progress and fall outside the new hours of operations. The orders need to be completed or cancelled prior to adjusting the hours of operations.'
          );
          return error;
        })
      );
  }

  clearOptionCategories() {
    this.optionCategories = [];
  }
}

export default MenuStore;
