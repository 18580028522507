import { useCallback, useContext } from 'react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import useEmployeeId from 'utils/helpers/user';

import {
  faFileAlt,
  faCalendarAlt,
  faHome,
  faCalculator,
  faUsers,
  faBell,
  faUtensils,
  faQuestionCircle,
  faTransgender,
  faArrowUp,
  faArrowsAltH,
} from '@fortawesome/free-solid-svg-icons';
import array from 'utils/helpers/array';

const tabsBasedOnRoles = {
  owner: {
    routes: [
      {
        label: 'Daily Order Summary',
        icon: faFileAlt,
        url: '/order-summary',
        tooltip: "Your dashboard for today's orders. View and manage all your orders from here.",
      },
      {
        label: 'Team Management',
        icon: faUsers,
        url: '/team-management',
        tooltip: "View and manage your team's access to the hungerhub restaurant portal",
      },
      {
        label: 'Accounting',
        icon: faCalculator,
        url: '/accounting',
        tooltip: "View and manage your restaurant's banking details and payment history.",
      },
      {
        label: 'Menu',
        icon: faUtensils,
        url: '/menu',
        tooltip: "View your restaurant's menu and request updates.",
      },
      {
        label: 'Schedule',
        icon: faCalendarAlt,
        url: '/schedule',
        tooltip: 'View a schedule of all upcoming orders and confirm your availability.',
      },
      {
        icon: faHome,
        label: 'Restaurant Profile',
        url: '/restaurant-profile',
        tooltip: "Manage your restaurant's address and pick up instructions.",
      },
      {
        icon: faBell,
        label: 'Notifications',
        url: '/notifications',
        tooltip: 'View your notification history.',
      },

      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },
  manager: {
    routes: [
      {
        label: 'Daily Order Summary',
        icon: faFileAlt,
        url: '/order-summary',
        tooltip: "Your dashboard for today's orders. View and manage all your orders from here.",
      },
      {
        label: 'Team Management',
        icon: faUsers,
        url: '/team-management',
        tooltip: "View and manage your team's access to the hungerhub restaurant portal",
      },
      {
        label: 'Menu',
        icon: faUtensils,
        url: '/menu',
        tooltip: "View your restaurant's menu and request updates.",
      },
      {
        label: 'Schedule',
        icon: faCalendarAlt,
        url: '/schedule',
        tooltip: 'View a schedule of all upcoming orders and confirm your availability.',
      },
      {
        icon: faHome,
        label: 'Restaurant Profile',
        url: '/restaurant-profile',
        tooltip: "Manage your restaurant's address and pick up instructions.",
      },
      {
        icon: faBell,
        label: 'Notifications',
        url: '/notifications',
        tooltip: 'View your notification history.',
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },
  employee: {
    routes: [
      {
        label: 'Daily Order Summary',
        icon: faFileAlt,
        url: '/order-summary',
        tooltip: "Your dashboard for today's orders. View and manage all your orders from here.",
      },
      {
        icon: faBell,
        label: 'Notifications',
        url: '/notifications',
        tooltip: 'View your notification history.',
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },
  accounting: {
    routes: [
      {
        label: 'Accounting',
        icon: faCalculator,
        url: '/accounting',
        tooltip: "View and manage your restaurant's banking details and payment history.",
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },

  common: {
    routes: [],
  },
};

export const useSideBarMethods = () => {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { odcMenuAvailableForRestaurant } = new useEmployeeId();

  const getSideBarTabs = useCallback(
    role => {
      let tabs = tabsBasedOnRoles[role]?.routes;

      if (['owner', 'manager'].includes(role) && !odcMenuAvailableForRestaurant) {
        tabs = [
          ...tabs.slice(0, -1), // all elements except the last one
          {
            icon: faArrowUp,
            label: 'Boost your sales',
            url: '/sales',
            tooltip: 'Boost your sales.',
            newTab: true,
            customIcon: true,
          },
          tabs.slice(-1)[0], // last element
        ];
      }
      return { routes: tabs };
    },
    [odcMenuAvailableForRestaurant]
  );

  return { getSideBarTabs };
};
