import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { HeedBadge } from 'components/HeedBadge';
import { Tooltip, SortButton, ETA } from 'components/Shared';
import { useHistory, Link } from 'react-router-dom';
import Screen from 'utils/helpers/ScreenSize';
import { NotFound } from 'components/Shared';
import Validation from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import array from 'utils/helpers/array';
import dateTime from 'utils/helpers/dateTime';
import orderhelper from 'utils/helpers/order';
import { useQueryMethods } from 'hooks/useQueryMethods';
import useEmployeeId from 'utils/helpers/user';
import object from 'utils/helpers/object';

function Td({ children, to, className }) {
  const ContentTag = to ? Link : 'div';
  return (
    <td className={`py-3 whitespace-nowrap relative ${className}`}>
      <ContentTag to={to}>{children}</ContentTag>
    </td>
  );
}

const statusTooltip = () => {
  return (
    <div>
      No Orders: No orders have been placed. Orders will start being placed closer to the order
      deadline.
      <br />
      <br />
      Pending: Orders are being placed and will continue until the order deadline. <br />
      <br />
      Pending(add lock symbol): Existing orders cannot be modified, but new orders can still be
      placed until the order deadline.
      <br />
      <br />
      Alert: There has been a change to the order that requires confirmation or at order deadline
      time please confirm no items require substitution.
      <br />
      <br />
      Confirmed: You have confirmed the order and a delivery driver will be on route shortly.
      <br />
      <br />
      ETA: The estimated arrival time of the delivery driver.
      <br />
      <br />
      In Transit: The driver has received the meal and is on route to the client.
      <br />
      <br />
      Delivered: The delivery is complete. <br />
      <br />
    </div>
  );
};

function CateringSummaryTable({ data, selectedDate }) {
  const history = useHistory();
  const store = useContext(StoreContext);
  const [currentSort, setCurrentSort] = useState('asc');
  const [sortedData, setSortedData] = useState(null);
  const screenType = Screen.ScreenType();
  const orderStore = toJS(store.orderStore);
  const { shipmentsETA } = orderStore;
  const { cateringShipmentRoute } = useQueryMethods();
  const { timeZone } = new useEmployeeId();

  useEffect(() => {
    setSortedData(data);
  }, [data?.data, sortedData]);

  const linkToPath = summary => {
    return cateringShipmentRoute(summary);
  };

  const sortByColumnName = column => {
    currentSort === 'asc' ? setCurrentSort('desc') : setCurrentSort('asc');
    //Put a timeout in order to prevent the weird effect of rerendering quickly
    setTimeout(() => {
      setSortedData(array.sortTableColumnWithoutAttributes(sortedData?.data, column, currentSort));
    }, [500]);
  };

  const findETA = shipmentId => {
    return !object.isEmpty(shipmentsETA) ? shipmentsETA[shipmentId] : null;
  };

  return (
    <div className="flex flex-col mt-2">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="t-head items-center text-left pl-2">
                    <small className="t-row">Menu</small>
                  </th>

                  <th scope="col" className="t-head items-center text-left">
                    <small className="t-row">
                      <Screen.Desktop>Destination Code</Screen.Desktop>
                      <Screen.Mobile>Dest.</Screen.Mobile>
                      <Screen.Tablet>Destination </Screen.Tablet>
                    </small>
                  </th>

                  <th scope="col" className="t-head text-left">
                    <div className="d-row justify-left invisible">
                      <small className="t-row">Bags</small>
                      <SortButton onClick={() => sortByColumnName('bags_count')} />
                    </div>
                  </th>

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head text-left">
                      <div className="d-row justify-left">
                        <small className="t-row">Items</small>
                        <SortButton onClick={() => sortByColumnName('items')} />
                      </div>
                    </th>
                  )}

                  <th scope="col" className="t-head text-left">
                    <div className="d-row justify-left invisible">
                      <small className="t-row">
                        {screenType.isTabletOrDesktop ? 'Order Deadline' : 'Deadline'}
                      </small>
                      <SortButton onClick={() => sortByColumnName('cutoff')} />
                    </div>
                  </th>

                  <th scope="col" className="t-head text-left">
                    <div className="d-row justify-left">
                      <small className="t-row">
                        {screenType.isTabletOrDesktop ? 'Pickup Time' : 'Pickup'}
                      </small>
                      <Screen.Desktop>
                        <Tooltip
                          tooltipText="Pickup time might change until deadline."
                          id="pickup-time"
                        />
                      </Screen.Desktop>

                      <SortButton onClick={() => sortByColumnName('pickup')} />
                    </div>
                  </th>

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head text-left">
                      <small className="t-row">Food Total</small>
                    </th>
                  )}

                  <th scope="col" className="t-head text-left">
                    <div className="d-row justify-left">
                      <small className="t-row">Status</small>
                      <Tooltip tooltipText="" custom children={statusTooltip()} id="status" />
                      <SortButton onClick={() => sortByColumnName('status')} />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {orderhelper.sortByCutoff(sortedData?.data)?.map((summary, index) => (
                  <tr key={index} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                    <Td to={() => linkToPath(summary)} className="pl-2 min-h-full">
                      <div className="flex items-center justify-left">
                        {screenType.isTabletOrDesktop && (
                          <FontAwesomeIcon icon={faExternalLinkAlt} color={'#393838'} size="sm" />
                        )}
                        <small className="input-label text-sm pl-2">{summary?.menu_name}</small>
                      </div>
                    </Td>

                    <Td to={() => linkToPath(summary)} className="min-h-full">
                      <small className="input-label text-sm">{summary?.destination_code}</small>
                    </Td>

                    <Td to={() => linkToPath(summary)}>
                      <div className="flex items-center justify-left"></div>
                    </Td>

                    {screenType.isTabletOrDesktop && (
                      <Td to={() => linkToPath(summary)}>
                        <div className="flex items-center justify-left">
                          <small className="input-label text-sm">{summary?.items}</small>
                        </div>
                      </Td>
                    )}

                    <Td to={() => linkToPath(summary)}>
                      <div className="flex items-center justify-left">
                        <small className="input-label text-sm"></small>
                      </div>
                    </Td>

                    <Td to={() => linkToPath(summary)}>
                      <div className="flex items-center justify-left">
                        <small className="input-label text-sm">
                          {summary.pickup
                            ? dateTime.formatTime(summary?.pickup, timeZone)
                            : 'Not Available'}
                        </small>
                      </div>
                    </Td>

                    {screenType.isTabletOrDesktop && (
                      <Td to={() => linkToPath(summary)}>
                        <div className="flex items-center justify-left">
                          <small className="input-label text-sm">
                            {Validation.priceFormatter(summary?.food_total)}
                          </small>
                        </div>
                      </Td>
                    )}

                    <Td to={() => linkToPath(summary)} className="justify-left flex py-3">
                      <div>
                        {summary?.status === 'confirmed' &&
                        (findETA(summary?.shipment_id)?.pickup_started ||
                          findETA(summary?.shipment_id)?.delivered ||
                          findETA(summary?.shipment_id)?.picked_up) ? (
                          <div className="animate-fade-in-down d-row">
                            <ETA eta={findETA(summary?.shipment_id)} />
                          </div>
                        ) : (
                          <HeedBadge
                            text={
                              summary?.shipment_acceptance_status === null
                                ? 'sent'
                                : summary?.status
                            }
                            className="capitalize"
                          />
                        )}
                      </div>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </table>

            <NotFound data={data?.data} message="No Orders Today" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(CateringSummaryTable);
