import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons';

function HeedBadge({ text, className, autoWidth }) {
  const getIcon = text => {
    switch (text) {
      case 'pending':
        return null;
      case 'preview':
        return faLock;
      case 'sent':
        return faExclamationTriangle;
      case 'confirmed':
        return null;
      default:
        return faExclamationTriangle;
    }
  };

  const getBackgroundColor = text => {
    switch (text) {
      case 'sent':
        return 'bg-status-alert';
      case 'Jason':
        return 'bg-status-jason';
      case 'pending':
      case 'preview':
        return 'bg-status-active';
      case 'confirmed':
        return 'bg-status-confirmed';
      case 'completed':
        return 'bg-status-completed';
      case 'capacity':
        return 'bg-purple-900';
      default:
        return 'bg-status-alert';
    }
  };

  const getTextColor = () => {
    switch (text) {
      case 'Alert':
        return 'text-white';
      case 'Jason':
        return 'text-orange-brown';
      case 'pending':
      case 'preview':
        return 'text-dark-gray';
      case 'confirmed':
        return 'text-dark-blue';
      case 'completed':
        return 'text-dark-green';
      default:
        return 'text-white';
    }
  };

  const getIconColor = () => {
    switch (text) {
      case 'Alert':
        return '#fff';
      case 'Jason':
        return '#D56D05';
      case 'pending':
      case 'preview':
        return '#5F5452';
      case 'confirmed':
        return '#5F5452';
      case 'completed':
        return '#5F5452';
      default:
        return 'white';
    }
  };

  const getTextMapping = () => {
    switch (text) {
      case 'pending':
        return 'Active';
      case 'preview':
        return 'Active';
      case 'sent':
        return 'Alert';
      case 'completed':
        return 'Delivered';
      default:
        return text;
    }
  };

  const getToolTip = () => {
    switch (text) {
      case 'pending':
        return 'Orders are being placed and will continue until the order deadline.';
      case 'preview':
        return 'Existing orders cannot be modified, but new orders can still be placed until the order deadline.';
      case 'sent':
        return 'There has been a change to the order that requires confirmation or at order deadline time please confirm no items require substitution.  ';
      case 'completed':
        return 'The delivery is complete.';
      case 'confirmed':
        return 'You have confirmed the order and a delivery driver will be on route shortly';
      case 'No Orders':
        return 'No orders have been placed. Orders will start being placed closer to the order deadline';
      default:
        return text;
    }
  };

  return (
    <div
      title={getToolTip()}
      className={`${getBackgroundColor(
        text
      )} rounded-full d-row justify-center items-center  ${className}`}
      style={{ width: autoWidth ? 'auto' : '100px', height: '30px' }}>
      {getIcon(text) && <FontAwesomeIcon icon={getIcon(text)} color={getIconColor()} size="xs" />}

      <small
        className={`font-inter-medium self-center text-center align-bottom px-1 ${getTextColor()} ${className}`}
        style={{ fontSize: '11px' }}>
        {getTextMapping()}
      </small>
    </div>
  );
}

export default HeedBadge;
