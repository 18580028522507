import DOMPurify from 'dompurify';
const EmbedHTML = ({ text, color = 'text-white', append = null }) => {
  return (
    <div
      className={`font-inter ${color} ${append && 'd-row'}`}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text + (append || '')) }}
    />
  );
};

export default EmbedHTML;
