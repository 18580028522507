export const isODCShipment = service => {
  return service === 'odc';
};

export const isUncateringShipment = service => {
  return service === 'uncatering';
};

export const isHungerhubBusiness = sourceOfBusiness => {
  return sourceOfBusiness === 'hungerhub';
};

export const isDirectCatering = sourceOfBusiness => {
  return sourceOfBusiness === 'restaurant';
};
