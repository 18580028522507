import React, { useEffect, useState, useContext, useMemo } from 'react';
import { CustomDatePicker } from 'components/DatePicker';
import { Bags } from 'assets/img';
import { Counter } from 'components/Counter';
import { HeedBadge } from 'components/HeedBadge';
import { SearchBar } from 'components/SearchBar';
import { OrderPrepareTable } from 'components/Table';
import { OrderSummary } from 'components/Alerts';
import { DestinationCodeSelect, AnimatePing, ETA } from 'components/Shared';
import Screen from 'utils/helpers/ScreenSize';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import useEmployeeId from 'utils/helpers/user';
import moment from 'moment';
import { OrderPrepareMobile } from 'components/Mobile';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import { useQueryMethods } from 'hooks/useQueryMethods';
import dateTime from 'utils/helpers/dateTime';
import { useHistory } from 'react-router-dom';
import array from 'utils/helpers/array';

function Prepare(props) {
  const { resourceId } = useQueryMethods();
  const [expandCollapse, setExpandCollapse] = useState(false);
  const [orderSummary, showOrderSummary] = useState(false);
  const [searchText, setSearchText] = useState('');
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const orderStore = toJS(store.orderStore);
  const userStore = toJS(store.userStore);
  const { resetFreshChat } = useFreshChatMethods();
  const { employeeId, timeZone } = new useEmployeeId();
  const { ordersPrepare, ordersPrepareTotalBags, ordersPrepareTotalItems, currentPath, eta } =
    orderStore;
  const isETAavailable = eta?.pickup_started || eta?.delivered || eta?.picked_up;
  const isShipmentCompleted = ordersPrepare?.status === 'completed';
  let history = useHistory();
  let params = { employee_id: employeeId };
  let etaInterval = null;
  let orderRefreshInterval = null;

  useEffect(() => {
    if (employeeId) {
      setExpandCollapse(true);

      orderRefreshInterval = orderRefreshInterval = setInterval(() => {
        getOrders();
      }, 300000);

      resetFreshChat();
      getETA();
      etaInterval = setInterval(() => {
        getETA();
      }, 120000);

      return () => {
        clearInterval(etaInterval);
        clearInterval(orderRefreshInterval);
      }; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, []);

  const getETA = () => {
    store.orderStore.getETA(resourceId(), params);
  };

  const getOrders = () => {
    store.userStore.setLoader(true);
    store.orderStore.getOrdersPrepare(params, resourceId()).then(response => {
      if (response?.data?.status === 'completed' || response?.data?.status === 'confirmed')
        clearInterval(orderRefreshInterval);
      store.userStore.setLoader(false);
    });
  };

  const onSearchChange = text => {
    setSearchText(text);
  };

  const confirmShipment = () => {
    store.userStore.setLoader(true);
    store.orderStore.confirmShipment(params, resourceId()).then(response => {
      getOrders();
      store.userStore.setLoader(false);
    });
  };

  const overrideShipmentCapacity = () => {
    store.userStore.setLoader(true);
    store.orderStore
      .overrideShipmentCapacity(params, resourceId(), { override: true })
      .then(response => {
        getOrders();
        store.userStore.setLoader(false);
      });
  };

  let withSentStatus = ordersPrepare?.data?.filter(
    ({ attributes: { status } }) => status === 'sent'
  );

  return (
    <div className="p-4 shadow-md border">
      <div className="d-col text-left">
        <strong className="input-label text-dark-gray">Menu</strong>

        <small className="text-sm text-dim-black font-inter-medium">
          {ordersPrepare?.menu_name}
        </small>
      </div>

      {screenType.isTabletOrDesktop ? (
        <div className="d-row justify-between">
          <div className="d-row">
            <div className="d-col w-1/2 lg:w-56 z-5 animate-fade-in-down">
              <small className="font-inter-regular text-dark-gray text-xs">Order</small>
              <DestinationCodeSelect
                cutoff={ordersPrepare?.cutoff}
                destinationCode={ordersPrepare?.destination_code}
                key={ordersPrepare}
              />
            </div>

            <div className="d-col px-4">
              <small className="font-inter-regular text-dark-gray text-xs">Date</small>
              <CustomDatePicker
                selectedDate={
                  ordersPrepare?.meal_date ? moment(ordersPrepare?.meal_date).toDate() : new Date()
                }
                disabled
              />
            </div>
          </div>

          <div className="d-row">
            <Counter label="Bags" count={ordersPrepareTotalBags} icon={Bags} />
            <Counter label="Items" count={ordersPrepareTotalItems} icon={Bags} />
            <Counter
              label="Cutlery"
              count={ordersPrepare?.cutlery_count}
              icon={faUtensils}
              fontAwesomeIcon
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="d-row justify-between">
            <div className="d-col lg:w-56 z-5">
              <DestinationCodeSelect
                cutoff={ordersPrepare?.cutoff}
                destinationCode={ordersPrepare?.destination_code}
                key={ordersPrepare}
              />
            </div>

            {withSentStatus?.length > 0 && (
              <AnimatePing className="w-24 mt-3">
                <HeedBadge text="Alert" />
              </AnimatePing>
            )}
          </div>

          <div className="d-row justify-between mt-3">
            <div className="w-1/2">
              <CustomDatePicker
                selectedDate={
                  ordersPrepare?.meal_date ? moment(ordersPrepare?.meal_date).toDate() : new Date()
                }
                disabled
              />
            </div>

            <div className="w-1/2 d-row justify-end">
              <Counter label="Bags" count={ordersPrepareTotalBags} icon={Bags} />
            </div>
          </div>

          <div className="d-row justify-between mt-2">
            <div className="w-1/2">
              <Counter label="Items" count={ordersPrepareTotalItems} icon={Bags} />
            </div>

            <div className="w-1/2 d-row justify-end">
              <Counter
                label="Cutlery"
                count={ordersPrepare?.cutlery_count}
                icon={faUtensils}
                fontAwesomeIcon
              />
            </div>
          </div>
        </div>
      )}

      {ordersPrepare?.stopped ? (
        <HeedBadge text={'capacity'} className="capitalize" />
      ) : (
        <div className="d-row">
          {ordersPrepare?.status === 'confirmed' && isETAavailable ? (
            <div className="animate-fade-in-down d-row">
              <ETA eta={eta} />
            </div>
          ) : (
            <div className="animate-fade-in-down d-row">
              {array.isPresent(withSentStatus) ? (
                <AnimatePing>
                  <HeedBadge text="Alert" />
                </AnimatePing>
              ) : (
                //if there is any order in the shipment with cancellation requested then passing status as 'sent' to get the alert pill
                <HeedBadge
                  text={ordersPrepare?.cancellation_requested ? 'sent' : ordersPrepare?.status}
                  className="capitalize"
                />
              )}
            </div>
          )}

          {ordersPrepare?.pickup && !isShipmentCompleted && !isETAavailable && (
            <small className="input-label font-inter-medium ml-2">
              Pick up time: {dateTime.formatTime(ordersPrepare?.pickup, timeZone)}
            </small>
          )}
        </div>
      )}

      {ordersPrepare?.stopped &&
        (ordersPrepare?.status === 'pending' || ordersPrepare?.status === 'preview') && (
          <small className="input-label">
            {`This order has reached its maximum capacity. If you are able to accommodate more orders
          before ${dateTime.formatTime(ordersPrepare?.cutoff, ordersPrepare?.tzinfo)} ${
              ordersPrepare?.pickup
                ? `and be on time for ${dateTime.formatTime(
                    ordersPrepare?.pickup,
                    ordersPrepare?.tzinfo
                  )}
          pick up time`
                : ''
            } , please click "Accept additional orders".`}
          </small>
        )}

      <div className="w-full d-row justify-end mt-5">
        <SearchBar onChange={onSearchChange} />

        <button className="btn-purple px-4 w-1/3 md:w-auto" onClick={() => showOrderSummary(true)}>
          <small>Summary</small>
        </button>
      </div>

      {screenType.isMobile ? (
        <OrderPrepareMobile
          data={ordersPrepare}
          handlePageClick={() => {}}
          withSentStatus={withSentStatus?.length > 0}
          currentPath={currentPath}
          eta={eta}
          searchText={searchText}
          confirmShipment={() => confirmShipment()}
          overrideShipmentCapacity={overrideShipmentCapacity}
        />
      ) : (
        <OrderPrepareTable
          data={ordersPrepare}
          handlePageClick={() => {}}
          withSentStatus={withSentStatus?.length > 0}
          searchText={searchText}
          currentPath={currentPath}
          eta={eta}
          key={ordersPrepare}
          confirmShipment={() => confirmShipment()}
          overrideShipmentCapacity={overrideShipmentCapacity}
        />
      )}

      {orderSummary && (
        <OrderSummary
          setAlert={() => showOrderSummary(false)}
          visible={orderSummary}
          data={ordersPrepare}
        />
      )}
    </div>
  );
}

export default observer(Prepare);
