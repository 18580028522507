import React, { useState, useMemo, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { BagDetail } from 'components/Alerts';
import { SortButton } from 'components/Shared';
import Screen from 'utils/helpers/ScreenSize';
import Validation from 'utils/helpers/validation';
import { SpecialInstruction } from 'assets/img';
import { NotFound } from 'components/Shared';
import array from 'utils/helpers/array';
import { StoreContext } from 'index';
import { useExportMethods } from 'hooks/useExportMethods';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import orderHelper from 'utils/helpers/order';
import { Tooltip } from 'components/Shared';
import dateTime from 'utils/helpers/dateTime';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import { useNotificationMethods } from 'hooks/useNotificationMethods';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

function OrderPrepareTable({
  data,
  withSentStatus,
  currentPath,
  searchText,
  confirmShipment,
  overrideShipmentCapacity,
}) {
  const [currentSort, setCurrentSort] = useState('asc');
  const [bagDetail, showBagDetail] = useState(null);
  const screenType = Screen.ScreenType();
  const [sortedData, setSortedData] = useState(null);
  const { exportPDF, exportLabel, exportXLS } = useExportMethods();
  const { resetFreshChat, openOrderChat } = useFreshChatMethods();
  const { filterOrdersPrepare, getOpacity } = orderHelper;
  const { timeZone } = new useEmployeeId();
  const { resourceId } = useQueryMethods();
  const { acceptDeclineCancellation } = useNotificationMethods();
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { isLoading } = userStore;
  const isRestaurantCapacityReached =
    data?.stopped && (data?.status === 'pending' || data?.status === 'preview');

  useEffect(() => {
    if (searchText === '') setSortedData(data);
  }, [data?.data, sortedData]);

  useMemo(() => {
    if (searchText) {
      setSortedData(prevState => ({
        ...prevState,
        data: filterOrdersPrepare(searchText, data),
      }));
    }
  }, [searchText]);

  const sortByColumnName = column => {
    setSortedData(
      array.sortTableColumnWithAttributes(
        sortedData?.data,
        column,
        currentSort === 'asc' ? 'desc' : 'asc'
      )
    );
    currentSort === 'asc' ? setCurrentSort('desc') : setCurrentSort('asc');
  };

  return (
    <div className="d-col mt-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full table-fixed">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="t-head text-center md:text-left lg:pl-2"
                    style={{ width: '12%' }}>
                    <div className={`${screenType.isDesktop && 'd-row justify-center'}`}>
                      <small className="t-row">
                        <Screen.Desktop>Bag Number</Screen.Desktop>
                        <Screen.Tablet>Bag No. </Screen.Tablet>
                      </small>

                      <Screen.Desktop>
                        {searchText === '' && (
                          <SortButton onClick={() => sortByColumnName('bag_number')} />
                        )}
                      </Screen.Desktop>
                    </div>
                  </th>

                  <th scope="col" className="t-head w-1/12 text-center">
                    <small className="t-row">Items</small>
                  </th>

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head w-1/6">
                      <small className="t-row">Label</small>
                    </th>
                  )}

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head w-3/12">
                      <div className="d-row pt-1">
                        <small className="t-row">Permanent Instructions</small>
                        <Tooltip
                          tooltipText="Please pay careful attention to allergies and contact support with any issues."
                          id="permanent-instructions"
                        />
                      </div>
                    </th>
                  )}

                  <th scope="col" className="t-head w-4/12">
                    <small className="t-row">Item</small>
                  </th>

                  <Screen.Desktop>
                    <th scope="col" className="t-head w-1/12 text-left">
                      <small className="t-row">Food Total</small>
                    </th>
                  </Screen.Desktop>

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head text-center w-1/12">
                      <div className={` ${screenType.isDesktop && 'd-row pt-1'}`}>
                        <Tooltip
                          tooltipText="Only include cutlery if the user has requested it, otherwise please do not include cutlery."
                          id="cutlery-instructions"
                        />
                        <small className="t-row">Cutlery</small>
                        {searchText === '' && (
                          <SortButton onClick={() => sortByColumnName('cutlery')} />
                        )}
                      </div>
                    </th>
                  )}
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {sortedData?.data?.map(
                  (
                    {
                      attributes: {
                        status,
                        order_items,
                        permanent_instructions,
                        subtotal,
                        grand_total,
                        cutlery,
                        user_name,
                        order_date,
                        cutoff,
                        hst,
                        bag_number,
                        id,
                        cancellation_requested,
                        ordered_at,
                      },
                    },
                    index
                  ) => (
                    <tr
                      key={index}
                      className="odd:bg-table-row hover:bg-blue-50 cursor-pointer animate-fade-in-down"
                      style={{
                        borderWidth: status === 'sent' ? 1 : 0,
                        borderColor: 'red',
                      }}>
                      <td className="py-3 relative align-middle">
                        <div className="d-col pl-3 justify-center">
                          {status === 'cancelled' ? (
                            <small className="text-red-500 font-inter-regular text-center">
                              Bag removed
                            </small>
                          ) : (
                            <small className="text-sm font-inter-regular pl-2 text-dark-gray">
                              {bag_number}
                            </small>
                          )}

                          <button
                            title="View full order including food total breakdown"
                            className="text-light-purple my-2 underline font-inter-regular text-xs align-bottom self-start"
                            onClick={e => {
                              e.stopPropagation();
                              resetFreshChat();
                              showBagDetail({
                                status,
                                order_items,
                                permanent_instructions,
                                subtotal,
                                grand_total,
                                cutlery,
                                user_name,
                                order_date,
                                cutoff,
                                bag_number,
                                ordered_at,
                                hst,
                              });
                            }}>
                            View Details
                          </button>

                          {cancellation_requested && (
                            <div>
                              <small className="text-xxs font-inter-regular text-dark-gray">
                                The user has requested to cancel this order
                              </small>

                              <div className="d-row mt-2 justify-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    acceptDeclineCancellation(id, true);
                                  }}
                                  className="bg-green-600 btn-purple p-1.5 rounded-md text-xs">
                                  Approve
                                </button>

                                <button
                                  onClick={() => acceptDeclineCancellation(id, false)}
                                  className="bg-red-700 btn-purple p-1.5 rounded-md text-xs">
                                  Decline
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>

                      <td className={`py-3 align-middle text-center ${getOpacity(status)}`}>
                        <small className="text-sm text-dark-gray font-inter-regular">
                          {order_items?.length}
                        </small>
                      </td>

                      {screenType.isTabletOrDesktop && (
                        <td
                          className={`py-3 break-words flex-row align-middle ${getOpacity(
                            status
                          )}`}>
                          <p className="text-sm d-row lg:text-sm text-dark-gray font-inter-regular">
                            {user_name} - {sortedData?.destination_code}
                          </p>
                        </td>
                      )}

                      <td
                        colSpan="3"
                        className={`py-3 text-dark-gray animate-fade-in-down ${getOpacity(
                          status
                        )}`}>
                        <table className="w-full table-fixed">
                          <tbody>
                            {order_items?.map(({ attributes }, orderItemIndex) => (
                              <tr key={orderItemIndex}>
                                <td>
                                  <table className="w-full table-fixed">
                                    <tbody>
                                      <tr key={orderItemIndex}>
                                        <td className="text-left" style={{ width: '28%' }}>
                                          {orderItemIndex === 0 && (
                                            <div className="d-col">
                                              {permanent_instructions ? (
                                                <div className="d-row">
                                                  <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    color={'#FF9966'}
                                                    size="sm"
                                                  />

                                                  <small className="text-sm text-dark-gray font-inter-regular pl-2">
                                                    <div title={permanent_instructions}>
                                                      {permanent_instructions}
                                                    </div>
                                                  </small>
                                                </div>
                                              ) : (
                                                <div />
                                              )}
                                            </div>
                                          )}
                                        </td>

                                        <td style={{ width: '58%' }}>
                                          <p className="overflow-ellipsis overflow-hidden text-xs d-row lg:text-sm text-black font-inter-regular">
                                            {attributes?.special_instructions && (
                                              <img
                                                src={SpecialInstruction}
                                                className="object-contain h-6 w-6 pr-2"
                                              />
                                            )}
                                            {attributes?.menu_item?.title} ({attributes?.category})
                                          </p>

                                          {attributes?.special_instructions && (
                                            <p className="overflow-ellipsis overflow-hidden text-xs lg:text-xs text-pink-500 font-inter-regular">
                                              {attributes?.special_instructions}
                                            </p>
                                          )}
                                        </td>

                                        <td
                                          className="text-left align-top text-black"
                                          style={{ width: '14%' }}>
                                          <small className="font-inter-regular text-xs lg:text-sm flex flex-col">
                                            {Validation.priceFormatter(attributes?.price)}
                                          </small>
                                        </td>
                                      </tr>

                                      <tr className="animate-fade-in-down">
                                        <td colSpan="4">
                                          <table className="w-full table-fixed">
                                            <tbody>
                                              {attributes?.order_item_options?.map(
                                                ({ attributes }, index) => (
                                                  <tr key={index}>
                                                    <td style={{ width: '28%' }} />

                                                    <td style={{ width: '58%' }}>
                                                      <div className="d-col">
                                                        <small className="font-inter-regular break-normal text-xs pt-2">
                                                          {attributes?.option_category_name}:{' '}
                                                          {attributes?.option_name}
                                                        </small>
                                                      </div>
                                                    </td>

                                                    <td
                                                      className="text-left flex items-start"
                                                      style={{ width: '14%' }}>
                                                      <div className="d-col">
                                                        <small className="font-inter-regular text-xs flex flex-col pt-2">
                                                          {Validation.priceFormatter(
                                                            attributes?.price
                                                          )}
                                                        </small>
                                                      </div>{' '}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>

                      {screenType.isTabletOrDesktop && (
                        <td className="py-3 align-middle text-center">
                          {cutlery ? (
                            <FontAwesomeIcon icon={faUtensils} color={'#6D69D3'} size="sm" />
                          ) : (
                            <div />
                          )}
                        </td>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <NotFound data={data?.data} message="No Bags Found" />
          </div>

          <BagDetail
            closeModal={() => {
              showBagDetail(null);
              resetFreshChat();
            }}
            destinationCode={sortedData?.destination_code}
            cutoff={sortedData?.cutoff}
            orderData={bagDetail}
            currentPath={currentPath}
          />

          <div className="d-col md:flex-row justify-between w-100 bg-white my-4 items-center">
            <div className="d-row">
              <button
                disabled={array.isEmpty(sortedData?.data)}
                className={`btn-outline ${array.isEmpty(sortedData?.data) && 'cursor-not-allowed'}`}
                title="Export a prefilled PDF of order labels to attach to each bag. Note: Do not prepare orders using labels as they do not include user options"
                onClick={() =>
                  exportLabel(sortedData?.destination_code, sortedData?.meal_date, resourceId())
                }>
                <small>Export Labels</small>
              </button>

              <button
                disabled={array.isEmpty(sortedData?.data)}
                className={`btn-outline mx-2 ${
                  array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
                }`}
                title="Export a prefilled PDF of order labels to attach to each bag. Note: Do not prepare orders using labels as they do not include user options"
                onClick={() =>
                  exportPDF(sortedData?.destination_code, sortedData?.meal_date, resourceId())
                }>
                <small>Export PDF</small>
              </button>

              <button
                disabled={array.isEmpty(sortedData?.data)}
                className={`btn-outline ${array.isEmpty(sortedData?.data) && 'cursor-not-allowed'}`}
                title="Export an XLS containing all order items, options & label details"
                onClick={() =>
                  exportXLS(sortedData?.destination_code, sortedData?.meal_date, resourceId())
                }>
                <small>Export XLS</small>
              </button>
            </div>

            <div className="w-full md:w-auto">
              {withSentStatus && !isLoading && (
                <button
                  onClick={() => confirmShipment()}
                  className="btn-purple w-full md:w-auto bg-red-600 px-14 lg:px-24 mt-2 md:mt-0">
                  <small>Confirm</small>
                </button>
              )}

              {isRestaurantCapacityReached && (
                <button
                  onClick={overrideShipmentCapacity}
                  className="btn-purple w-full md:w-auto mt-2 md:mt-0">
                  <small>Accept additional orders</small>
                </button>
              )}

              <button
                disabled={currentPath?.help}
                onClick={() =>
                  openOrderChat(
                    sortedData?.destination_code,
                    dateTime.formatTime(sortedData?.cutoff, timeZone)
                  )
                }
                className={`btn-purple px-10 ${currentPath?.help && 'opacity-50'}`}>
                <small>Help</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(OrderPrepareTable);
