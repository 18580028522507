import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const useQueryMethods = () => {
  let query = useQuery();
  const { id } = useParams(); //office user id

  const getParam = useCallback(key => {
    return query.get(key);
  });

  const getAllParams = useCallback(key => {
    return query;
  });

  const resourceId = useCallback(() => {
    return id;
  });

  const uncateringShipmentRoute = shipment => {
    return `/uncatering-order/${shipment?.shipment_id}`;
  };

  const cateringShipmentRoute = shipment => {
    return `/catering-order/${shipment?.shipment_id}?source_of_business=${shipment?.source_of_business}`;
  };

  const getCurrentURL = () => {
    const currentURL = window.location.href;
    return currentURL;
  };

  return {
    getParam,
    getAllParams,
    resourceId,
    cateringShipmentRoute,
    uncateringShipmentRoute,
    getCurrentURL,
  };
};
