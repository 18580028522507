import React, { useState, useContext, useEffect } from 'react';
import { CustomDatePicker } from 'components/DatePicker';
import { Counter } from 'components/Counter';
import { HeedBadge } from 'components/HeedBadge';
import { BoostSales } from 'components/BoostSales';
import { Receipt, ReceiptActive } from 'assets/img';
import { SummaryTable, CateringSummaryTable } from 'components/Table';
import { useHistory } from 'react-router-dom';
import { BagAdded } from 'components/Alerts';
import Screen from 'utils/helpers/ScreenSize';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { AnimatePing } from 'components/Shared';
import moment from 'moment';
import { SummaryMobile, CateringSummaryMobile } from 'components/Mobile';
import pluralize from 'pluralize';
import { useQueryMethods } from 'hooks/useQueryMethods';
import useEmployeeId from 'utils/helpers/user';
import array from 'utils/helpers/array';

function Summary() {
  const [visible, setVisible] = useState(false);
  const store = useContext(StoreContext);
  const screenType = Screen.ScreenType();
  const orderStore = toJS(store.orderStore);
  const userStore = toJS(store.userStore);
  const { orderSummaries, cateringSummaries, hungerhubCateringSummaries, directCateringSummaries } =
    orderStore;
  const { defaultDateSelect } = userStore;
  const history = useHistory();
  const { getParam } = useQueryMethods();
  const { timeZone } = new useEmployeeId();

  useEffect(() => {
    let interval = null;
    let interval2 = null;

    store.userStore.setLoader(true);
    if (userStore?.defaultRestaurant?.value) {
      store.orderStore
        .getOrderSummaries(
          {
            employee_id: userStore?.defaultRestaurant?.value,
            date: getParam('date')
              ? getParam('date')
              : moment(defaultDateSelect).format('YYYY-MM-DD'),
            service: 'uncatering',
          },
          timeZone
        )
        .then(response => {
          store.userStore.setLoader(false);
          getETAs(response);
          interval = setInterval(() => {
            getETAs(response);
          }, 120000);
        });

      store.orderStore
        .getHungerhubCateringSummaries({
          employee_id: userStore?.defaultRestaurant?.value,
          date: getParam('date')
            ? getParam('date')
            : moment(defaultDateSelect).format('YYYY-MM-DD'),
          service: 'odc',
        })
        .then(response => {
          store.userStore.setLoader(false);
          getETAs(response);
          interval2 = setInterval(() => {
            getETAs(response);
          }, 120000);
        });
    }

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    }; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [moment(defaultDateSelect).format('YYYY-MM-DD')]);

  const getETAs = response => {
    let shipmentIds = response?.data?.data?.map(item => item.shipment_id);
    if (array.isPresent(shipmentIds))
      store.orderStore.getShipmentsETA(
        1,
        {
          employee_id: userStore?.defaultRestaurant?.value,
        },
        shipmentIds
      );
  };

  const getSelectedDate = () =>
    moment(getParam('date') ? getParam('date') : new Date(defaultDateSelect)).toDate();

  let withSentStatus = orderSummaries?.data
    ?.concat(hungerhubCateringSummaries?.data)
    ?.filter(summary => summary?.status === 'sent');

  return (
    <div className="border-2 shadow-md rounded-lg p-5 bg-white text-left min-h-screen flex flex-col">
      <div className="flex flex-row justify-between items-start">
        <div className="text-left overflow-hidden">
          <CustomDatePicker
            setDate={date => {
              store.orderStore.resetShipmentsETA();
              store.userStore.setDefaultSummaryDate(date);
              history.replace('/order-summary');
            }}
            selectedDate={getSelectedDate()}
          />
        </div>

        <BagAdded setAlert={visbile => setVisible(visbile)} visible={visible} />

        <div className="d-col items-start md:flex-row">
          {screenType.isDesktop && (
            <Counter
              label="Total Orders"
              count={orderSummaries?.total_orders + cateringSummaries?.total_orders}
              icon={Receipt}
            />
          )}

          {screenType.isDesktop && (
            <Counter
              label="Active Orders"
              count={orderSummaries?.active_orders + cateringSummaries?.active_orders}
              icon={ReceiptActive}
            />
          )}
        </div>
      </div>

      {array.isPresent(withSentStatus) && (
        <div className="mr-auto">
          <Screen.Desktop>
            <AnimatePing>
              <HeedBadge
                autoWidth
                text={`You have ${pluralize(
                  'order',
                  withSentStatus?.length,
                  true
                )} that requires attention`}
                className="text-sm p-1.5 pl-3 w-auto rounded-full normal-case truncate"
              />
            </AnimatePing>
          </Screen.Desktop>
        </div>
      )}

      <small className="input-label text-sm pt-3">
        Click on an order to view the details of the order and confirm any alerts.
      </small>

      <div className="d-row mb-2 justify-between items-end">
        <strong className="mt-4">Uncatering Orders</strong>

        <div className="d-row">
          <div className="h-3 w-3 bg-purple-300 mr-1" />
          <small className="input-label">Restaurant Capacity</small>
        </div>
      </div>

      {screenType.isTabletOrDesktop ? (
        <SummaryTable data={orderSummaries} selectedDate={defaultDateSelect} />
      ) : (
        <SummaryMobile data={orderSummaries} selectedDate={defaultDateSelect} />
      )}

      <strong style={{ marginTop: '50px' }}>hungerhub Catering Orders</strong>

      {screenType.isTabletOrDesktop ? (
        <CateringSummaryTable data={hungerhubCateringSummaries} selectedDate={defaultDateSelect} />
      ) : (
        <CateringSummaryMobile data={hungerhubCateringSummaries} selectedDate={defaultDateSelect} />
      )}

      <strong style={{ marginTop: '50px' }}>Direct Catering Orders</strong>

      {userStore?.defaultRestaurant?.availableForOdc ? (
        <>
          {screenType.isTabletOrDesktop ? (
            <CateringSummaryTable data={directCateringSummaries} selectedDate={defaultDateSelect} />
          ) : (
            <CateringSummaryMobile
              data={directCateringSummaries}
              selectedDate={defaultDateSelect}
            />
          )}
        </>
      ) : (
        <BoostSales />
      )}

      <Screen.Mobile>
        <small className="input-label text-sm pt-3">
          *Pickup time might change until deadline.
        </small>
      </Screen.Mobile>
    </div>
  );
}

export default observer(Summary);
