import React, { useState, useContext, useEffect } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import dateTime from 'utils/helpers/dateTime';
import Screen from 'utils/helpers/ScreenSize';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import useEmployeeId from 'utils/helpers/user';
import orderHelper from 'utils/helpers/order';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import object from 'utils/helpers/object';
import { useQueryMethods } from 'hooks/useQueryMethods';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Notifications() {
  const [startDate, setStartDate] = useState(null);
  const store = useContext(StoreContext);
  const screenType = Screen.ScreenType();
  const userStore = toJS(store.userStore);
  let history = useHistory();
  const notificationStore = toJS(store.notificationStore);
  const { employeeId, timeZone } = new useEmployeeId();
  const { unreadNotifications } = notificationStore;
  const { notificationDescription, getBusinessSource } = orderHelper;
  const { uncateringShipmentRoute, cateringShipmentRoute } = useQueryMethods();

  useEffect(() => {
    // refreshNotifications();
  }, []);

  const refreshNotifications = () => {
    store.notificationStore.getNotifications(
      {
        employee_id: employeeId,
        date: dateTime.formatDate(new Date(), 'YYYY-MM-DD', timeZone),
        per_page: 5,
        page: 1,
        status: 'unread',
      },
      true
    );
  };

  const clearNotifications = () => {
    if (!object.isEmpty(unreadNotifications)) {
      store.userStore.setLoader(true);
      let ids = unreadNotifications[dateTime.formatDate(new Date(), 'YYYY-MM-DD', timeZone)]?.map(
        notification => notification.id
      );
      let payload = { notification_ids: ids };
      if (ids?.length > 0) {
        store.userStore.setLoader(true);
        store.notificationStore
          .clearNotifications(ids[0], payload, { employee_id: employeeId })
          .then(response => {
            refreshNotifications();
          });
        store.userStore.setLoader(false);
      }
      store.userStore.setLoader(false);
    }
  };

  const navigateToOrders = notification => {
    let type = notification?.type;
    let navigateTo = '';
    if (
      type === 'unconfirmed_shipment_reminder' ||
      type === 'shipment_removed' ||
      type === 'expecte_bags_changed'
    ) {
      navigateTo = `/schedule?start_date=${notification?.data?.date}`;
    } else if (type === 'additional_shipment_added' || type === 'all_shipments_published') {
      navigateTo = `/schedule?start_date=${notification?.data?.start_date}`;
    } else if (
      type === 'hungerhub_catering_order_added' ||
      type === 'new_catering_order' ||
      type === 'hungerhub_catering_order_confirmation' ||
      type === 'direct_catering_order_confirmation'
    ) {
      navigateTo = cateringShipmentRoute({
        shipment_id: notification?.data?.shipment_id,
        source_of_business: getBusinessSource(type),
      });
    } else if (
      type === 'hungerhub_catering_order_cancellation' ||
      type === 'direct_catering_order_cancellation'
    )
      navigateTo = `/order-summary?date=${notification?.data?.date}`;
    else
      navigateTo = uncateringShipmentRoute({
        shipment_id: notification?.data?.shipment_id,
      });

    store.orderStore.setActionCablePayload(null);
    clearNotification(notification);
    history.push(navigateTo);
  };

  const clearNotification = notification => {
    let payload = { notification_ids: [notification.id] };
    store.notificationStore
      .clearNotifications(notification?.id, payload, { employee_id: employeeId })
      .then(() => {
        refreshNotifications();
      });
  };

  return (
    <Menu as="div" className="relative inline-block text-left mr-1 lg:mr-0">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm py-2 focus:outline-none">
          <div
            onClick={() => refreshNotifications()}
            className="relative md:self-center self-start md:mr-3 ml-1 md:ml-0">
            {!object.isEmpty(unreadNotifications) && (
              <div className="h-3 w-3 rounded-full bg-red-600 absolute right-0" />
            )}

            <FontAwesomeIcon
              id="bell-icon-svg"
              icon={faBell}
              size="lg"
              className="self-center mr-0 cursor-pointer"
              color="#6D69D3"
            />
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="origin-top-right absolute rounded-lg right-0 mt-2 w-96 shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" key={unreadNotifications}>
            {Object.keys(unreadNotifications ? unreadNotifications : {}).map(function (key, index) {
              return (
                <div key={index} className="d-col px-4 cursor-pointer">
                  {unreadNotifications[key]?.map(notification => {
                    return (
                      <div
                        onClick={() => {
                          navigateToOrders(notification);
                        }}
                        className="d-col justify-between py-2 cursor-pointer hover:bg-gray-100 border-b-2">
                        <span className="font-inter-medium text-xs">
                          <small className="input-label text-dark-gray">
                            {notificationDescription(notification, timeZone)}
                          </small>
                        </span>

                        <div className="d-row justify-between mt-2">
                          <small className="text-xs font-inter-medium text-dark-gray self-end">
                            {moment(notification.created_at).local().fromNow()}
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div className="d-row justify-between px-12 py-4">
              <button
                onClick={clearNotifications}
                className="text-dark-gray hover:text-black font-inter-medium">
                {object.isEmpty(unreadNotifications) ? 'No new notifications' : 'Clear'}
              </button>

              <button
                onClick={() => {
                  clearNotifications();
                  setTimeout(() => {
                    history.replace('/notifications');
                  }, 1000);
                }}
                className="text-light-purple hover:text-purple-900 font-inter-medium">
                View All
              </button>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default observer(Notifications);
