import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Prepare } from './index';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import object from 'utils/helpers/object';
import { useHistory } from 'react-router-dom';
import { ConfirmationPopup } from 'components/Shared';

function OrderDetail(props) {
  const store = useContext(StoreContext);
  const [declinedAlert, setDeclinedAlert] = useState(false);
  const { resourceId } = useQueryMethods();
  const { employeeId, timeZone } = new useEmployeeId();
  const orderStore = toJS(store.orderStore);
  const history = useHistory();
  let { actionCablePayload } = orderStore;

  useEffect(() => {
    window?.fcWidget?.destroy();
    store.orderStore.setCurrentPath({ pathName: '/order' });

    return () => {
      store.orderStore.setCurrentPath(null);
    };
  }, []);

  useEffect(() => {
    getOrderDetail();
  }, [object.isPresent(actionCablePayload)]);

  const getOrderDetail = () => {
    store.userStore.setLoader(true);
    store.orderStore.getOrdersPrepare({ employee_id: employeeId }, resourceId()).then(response => {
      store.userStore.setLoader(false);
      if (response?.data?.status === 'declined') {
        setDeclinedAlert(true);
      } else {
        store.orderStore.getOrderSummaries(
          {
            employee_id: employeeId,
            date: response?.data?.meal_date,
            service: 'uncatering',
          },
          timeZone
        );
        store.orderStore.getHungerhubCateringSummaries({
          employee_id: employeeId,
          date: response?.data?.meal_date,
          service: 'odc',
        });
      }
    });
  };

  return (
    <div className="bg-white text-left min-h-screen d-col">
      <Prepare />

      <ConfirmationPopup
        visible={false}
        btnRight="Okay"
        title=""
        subtitle="The selected order has been cancelled. Please visit the schedule page to view upcoming
          orders. If you need any assistance please start a chat."
        handleReject={() => {
          setDeclinedAlert(false);
          history.replace('/schedule');
        }}
        threeActions={'true'}
      />
    </div>
  );
}

export default observer(OrderDetail);
