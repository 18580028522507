import qs from 'qs';
import api from '../store/api';
import { makeObservable, observable, action, computed, flow, toJS } from 'mobx';
import { client } from '../store';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import moment from 'moment';
import axios from 'axios';
import LocalStorage from 'utils/helpers/localStorage';
import dateTime from 'utils/helpers/dateTime';
import { errorHandler } from 'utils/middlewares/errorHandler';

class OrderStore {
  orderSummaries = {};
  cateringSummaries = {};
  hungerhubCateringSummaries = {};
  directCateringSummaries = {};
  ordersPrepare = null;
  ordersPrepareTotalBags = 0;
  ordersPrepareTotalItems = 0;

  destinationCodes = [];
  defaultDestinationCode = {};
  eta = null;
  shipmentsETA = null;
  actionCablePayload = null;

  currentPath = null;

  constructor() {
    makePersistable(this, {
      name: 'OrderStore',
      properties: ['defaultDestinationCode', 'destinationCodes'],
      storage: window.localStorage,
    });

    makeObservable(this, {
      orderSummaries: observable,
      cateringSummaries: observable,
      hungerhubCateringSummaries: observable,
      directCateringSummaries: observable,
      ordersPrepare: observable,
      eta: observable,
      shipmentsETA: observable,
      ordersPrepareTotalBags: observable,
      ordersPrepareTotalItems: observable,
      actionCablePayload: observable,
      destinationCodes: observable,
      defaultDestinationCode: observable,
      currentPath: observable,
      getOrderSummaries: action,
      getHungerhubCateringSummaries: action,
    });
  }

  async getOrderSummaries(params, timeZone) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.orderSummaries()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.orderSummaries = data.data;
          let _destinationCodes = [];
          data?.data?.data?.map(summary =>
            _destinationCodes.push({
              label: `${dateTime.formatTime(summary.cutoff, timeZone)} - ${
                summary.destination_code
              }`,
              value: summary,
              shipment_id: summary.shipment_id,
            })
          );
          this.destinationCodes = _destinationCodes;
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  async getHungerhubCateringSummaries(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.orderSummaries()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.cateringSummaries = data.data;

          this.hungerhubCateringSummaries = {
            data: data.data.data.filter(order => order.source_of_business == 'hungerhub'),
          };

          this.directCateringSummaries = {
            data: data.data.data.filter(order => order.source_of_business == 'restaurant'),
          };
          let _destinationCodes = [];
          data?.data?.data?.map(summary =>
            _destinationCodes.push({
              label: `${summary.destination_code} - ${
                summary.source_of_business == 'hungerhub' ? 'hungerhub Catering' : 'Direct Catering'
              }`,
              value: summary,
              shipment_id: summary.shipment_id,
            })
          );
          this.destinationCodes = this.destinationCodes.concat(_destinationCodes);
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  async getETA(shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.eta()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.eta = data.data;
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentsETA(shipmentId, params, arr) {
    let paramsString = qs.stringify(params);

    let shipmentParams = qs.stringify(
      {
        shipment_ids: arr,
      },
      { arrayFormat: 'brackets' }
    );

    return client()
      .get(`${api.eta()}?${paramsString}&${shipmentParams}`)
      .then(
        action('fetchSuccess', data => {
          let allETAs = toJS(this.shipmentsETA);
          let etas = data?.data;
          let obj = Object.assign({}, allETAs, etas);

          this.shipmentsETA = obj;
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  resetShipmentsETA() {
    this.shipmentsETA = [];
  }

  setDefaultDestinationCode(destinationCode) {
    this.defaultDestinationCode = destinationCode;
  }

  async getOrdersPrepare(params, shipmentId, toUpdateStore = true) {
    this.ordersPrepare = null;
    let paramsString = qs.stringify(params);
    //setting up axios here again because this specific request required Accept: 'application/json' in the headers. Else it would't work
    const API_URL = process.env.REACT_APP_API_URL_PROD;

    let access_token = LocalStorage.getAccessToken();
    let url = `${API_URL}${api.orders()}/${shipmentId}?${paramsString}`;

    return axios({
      url,
      method: 'GET',
      headers: {
        'ACCESS-TOKEN': access_token,
        Accept: 'application/json',
      },
    }).then(
      action('fetchSuccess', data => {
        if (toUpdateStore) {
          this.ordersPrepare = data.data;
          this.ordersPrepareTotalBags = data?.data?.total_bags;
          this.ordersPrepareTotalItems = data?.data?.items_count;
        }
        return data;
      }),
      action('fetchError', error => {
        errorHandler(error.response);
        return error;
      })
    );
  }

  setCurrentPath(params) {
    this.currentPath = params;
  }

  async exportXLS(params) {
    let access_token = LocalStorage.getAccessToken();

    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/v1/restaurant_admin/shipments/${params.shipment_id}.xlsx?employee_id=${params.employee_id}&date=${params.date}`;

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/xlsx' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.date}_${params.destination_code}.xlsx`;
        link?.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        errorHandler(error.response);
        return error;
      })
    );
  }

  async exportPDF(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/v1/restaurant_admin/shipments/${params.shipment_id}.pdf?employee_id=${params.employee_id}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.date}_${params.destination_code}.pdf`;
        link?.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        errorHandler(error.response);
        return error;
      })
    );
  }

  async exportLabel(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/v1/restaurant_admin/shipments/${params.shipment_id}.pdf?employee_id=${params.employee_id}&order_labels_export=${params.order_labels_export}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.date}_${params.destination_code}.pdf`;
        link?.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        errorHandler(error.response);
        return error;
      })
    );
  }

  async confirmShipment(params, shipmentId) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.orders()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async overrideShipmentCapacity(params, shipmentId, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.orders()}/${shipmentId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  setActionCablePayload = payload => {
    this.actionCablePayload = payload;
    return payload;
  };

  acceptDeclineCancellation = (orderId, params, approved) => {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.cancellation()}/${orderId}?${paramsString}`, { approved: approved })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  };
}

export default OrderStore;
