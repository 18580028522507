import React, { useReducer, useContext, useState, useMemo } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import { ModalBackground, ToggleButton, Dropdown, ErrorToast, Button } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import { CustomDatePicker } from 'components/DatePicker';
import { faPlus, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import dateTime from 'utils/helpers/dateTime';
import moment from 'moment';
import array from 'utils/helpers/array';
import Screen from 'utils/helpers/ScreenSize';
import { errorHandler } from 'utils/middlewares/errorHandler';
import TPicker from 'components/TimePicker';
import {
  default12amTime,
  fullDateFormat,
  defaultStartTime,
  defaultEndTime,
} from 'utils/helpers/dateTime';
import { isOperational } from 'utils/helpers/hoursOfOperation';

function HoursOfOperations({
  visible,
  closeModal,
  operatingHours,
  specialHours,
  type,
  associatedMenus = [],
  menuId,
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const [weekDays, setWeekDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([]);
  const screenType = Screen.ScreenType();
  const { defaultRestaurant } = userStore;
  const timeZone = defaultRestaurant?.timeZone;
  const initialState = {
    menus: [],
    menusError: false,
    showTimeError: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useMemo(() => {
    let daysArrayUpdate = [
      { day: 'sunday', operational: false, hours: [] },
      { day: 'monday', operational: false, hours: [] },
      { day: 'tuesday', operational: false, hours: [] },
      { day: 'wednesday', operational: false, hours: [] },
      { day: 'thursday', operational: false, hours: [] },
      { day: 'friday', operational: false, hours: [] },
      { day: 'saturday', operational: false, hours: [] },
    ];

    operatingHours?.map(({ attributes: { from, to, day, active }, id }) => {
      let dayFound = daysArrayUpdate.find(o => o.day === day);

      dayFound?.hours.push({ start: from, end: to, id: id, active: active });
      dayFound.operational = dayFound?.hours.filter(hour => !hour.active)?.length === 0;
      dayFound.id = id;
      return dayFound;
    });

    if ((type === 'menu' && weekDays?.length === 0) || type === 'restaurant')
      setWeekDays(daysArrayUpdate);
  }, [operatingHours, visible]);

  useMemo(() => {
    let specialDaysUpdate = [];

    specialHours?.map(specialHour => {
      let day = specialHour.attributes;
      let dayFound = specialDaysUpdate.find(o =>
        moment(o.date).isSame(moment(dateTime.splitDate(day?.date)).toDate())
      );

      if (dayFound) {
        dayFound.hours.push({
          start: day?.from,
          end: day?.to,
          id: specialHour.id,
        });
        dayFound.date = moment(dateTime.splitDate(day?.date)).toDate();
      } else {
        specialDaysUpdate = [
          ...specialDaysUpdate,
          {
            date: moment(dateTime.splitDate(day?.date)).toDate(),
            operational: false,
            hours: [
              {
                start: day?.from,
                end: day?.to,
                id: specialHour.id,
              },
            ],
          },
        ];
      }
    });

    specialDaysUpdate?.map(day =>
      day.hours.map(hour => {
        if (hour.start !== default12amTime && hour.end !== default12amTime) day.operational = true;
      })
    );

    setSpecialDays(specialDaysUpdate);
  }, [specialHours, visible]);

  // handle click event of the Remove button
  const handleRemoveClick = (selected, index) => {
    let updated = weekDays?.map(weekDay => {
      if (weekDay?.day === selected.day) {
        const list = [...weekDay?.hours];
        let hour = list[index];
        if (hour.id) hour._destroy = true;
        else list.splice(index, 1);
        weekDay.hours = list;
      }
      return weekDay;
    });
    setWeekDays(updated);
  };

  // handle click event of the Add button
  const handleAddClick = selected => {
    let updated = weekDays?.map(weekDay => {
      if (weekDay?.day === selected.day) {
        weekDay.hours = [...weekDay.hours, { start: defaultStartTime, end: defaultEndTime }];
      }
      return weekDay;
    });

    setWeekDays(updated);
  };

  const setToggleState = (checked, selected) => {
    let payload = {
      day: selected.day,
      active: checked,
      type: type === 'restaurant' ? 'Restaurant' : 'Menu',
    };

    if (type === 'menu') payload.menu_id = menuId;

    store.userStore.setLoader(true);
    store.userStore.updateHoursAvailability({ employee_id: employeeId }, payload).then(() => {
      store.userStore.setLoader(false);
    });

    let updated = weekDays?.map(weekDay => {
      if (weekDay?.day === selected.day) {
        weekDay.operational = checked;
        if (checked && array.isEmpty(weekDay.hours))
          weekDay.hours = [{ start: defaultStartTime, end: defaultEndTime, active: true }];
      }
      return weekDay;
    });

    setWeekDays(updated);
  };

  const handleTimeChange = (withoutFormatTime, selected, index, picker) => {
    const time = dateTime.to24HourFormat(withoutFormatTime);

    let updated = weekDays?.map(weekDay => {
      if (weekDay?.day === selected.day) {
        let list = [...weekDay.hours];
        if (picker === 'start') list[index].start = time;
        else list[index].end = time;
        weekDay.hours = list;
      }
      return weekDay;
    });

    setWeekDays(updated);
  };

  //for special days

  const handleAddSpecialDays = () => {
    let special = [
      ...specialDays,
      {
        date: new Date(),
        operational: false,
        hours: [{ start: default12amTime, end: default12amTime }],
      },
    ];
    setSpecialDays(special);
  };

  const handleRemoveSpecialDay = (day, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        day.removed = true;
        const list = [...day.hours];
        day.hours = list?.map(hour => {
          if (hour.id) return { ...hour, _destroy: true };
          return hour;
        });
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const setSpecialDayAvailability = (checked, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        day.operational = checked;

        if (!checked) {
          const list = [...day.hours];

          day.hours = list
            ?.map(hour => {
              if (hour.id && hour.start === default12amTime && hour.end === default12amTime)
                return { ...hour, _destroy: false };
              return { ...hour, _destroy: true };
            })
            .filter(hour => hour.id);

          if (
            array.isEmpty(
              day.hours.filter(
                hour => hour.start === default12amTime && hour.end === default12amTime
              )
            )
          )
            day.hours = [...day.hours, { start: default12amTime, end: default12amTime }];
        } else {
          const list = [...day.hours];
          day.hours = list
            ?.map(hour => {
              if (hour.id && hour.start === default12amTime && hour.end === default12amTime)
                return { ...hour, _destroy: true };
              return { ...hour, _destroy: false };
            })
            .filter(hour => {
              return hour.id && hour.start !== default12amTime && hour.end !== default12amTime;
            });

          if (
            array.isEmpty(
              day.hours.filter(
                hour => hour.start !== default12amTime && hour.end !== default12amTime
              )
            )
          )
            day.hours = [
              ...day.hours,
              { start: defaultStartTime, end: defaultEndTime, active: true },
            ];
        }
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const setSpecialDayDate = (date, index) => {
    if (
      specialDays?.filter(
        specialDay =>
          dateTime.formatDateWithTimezone(specialDay?.date, fullDateFormat, timeZone) ===
          dateTime.formatDateWithTimezone(date, fullDateFormat, timeZone)
      )?.length === 0
    ) {
      let updated = specialDays?.map((day, ind) => {
        if (ind === index) {
          day.date = date;
        }
        return day;
      });
      setSpecialDays(updated);
    }
  };

  const handleAddSpecialHour = (day, index) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === index) {
        day.hours = [...day.hours, { start: defaultStartTime, end: defaultEndTime }];
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const handleRemoveSpecialHour = (selected, hourIndex, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        const list = [...day.hours];
        let hour = list[hourIndex];
        if (hour.id) hour._destroy = true;
        else list.splice(hourIndex, 1);
        day.hours = list;
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const handleSpecialDayTime = (withoutFormatTime, selected, hourIndex, picker, dayIndex) => {
    const time = dateTime.to24HourFormat(withoutFormatTime);
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        let list = day?.hours?.filter(
          hour => hour.start !== default12amTime && hour.end !== default12amTime
        );
        if (picker === 'start') list[hourIndex].start = time;
        else list[hourIndex].end = time;
        day.hours = list;
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const ifInvalidTimes = () => {
    let incorrectTimes = [];

    weekDays?.map(
      day =>
        isOperational(day?.operational) &&
        day?.hours?.map(time => {
          incorrectTimes = [
            ...incorrectTimes,
            dateTime.checkEndTimeAfterStartTime(time?.start, time?.end),
          ];
        })
    );

    specialDays?.map(
      day =>
        isOperational(day?.operational) &&
        day?.hours?.map(time => {
          incorrectTimes = [
            ...incorrectTimes,
            dateTime.checkEndTimeAfterStartTime(time?.start, time?.end),
          ];
        })
    );

    return incorrectTimes.some(item => !item);
  };

  const saveHours = () => {
    if (
      type === 'restaurant' &&
      array.isPresent(associatedMenus) &&
      array.isEmpty(provider.menus)
    ) {
      dispatch({ field: 'menusError', value: true });
      return;
    }

    if (ifInvalidTimes()) {
      errorHandler({
        title: 'Start time should be before the end time.',
      });
    } else {
      let payload = { menu_ids: provider?.menus?.map(menu => menu?.value) };

      let operatingHours = {
        operating_hours_attributes: weekDays
          ?.map(weekDay => {
            return weekDay?.hours?.map(hour => {
              let slot = {
                day: weekDay.day,
                from: hour.start,
                to: hour.end,
                selected_menu_ids: provider?.menus?.map(menu => menu?.value),
              };
              if (hour?.id) slot.id = hour.id;
              if (hour?._destroy) slot._destroy = hour?._destroy;
              return slot;
            });
          })
          .flat(),

        special_hours_attributes: specialDays
          ?.filter(day => day.date)
          ?.map(day => {
            return day?.hours?.map(hour => {
              let slot = {
                date: moment(day.date).format(fullDateFormat),
                from: hour.start,
                to: hour.end,
                selected_menu_ids: provider?.menus?.map(menu => menu?.value),
              };
              if (hour?.id) slot.id = hour.id;
              if (hour?._destroy) slot._destroy = hour?._destroy;
              return slot;
            });
          })
          .flat(),
      };

      if (type === 'restaurant') {
        payload.restaurant = operatingHours;

        store.userStore.setLoader(true);
        store.userStore.updateRestaurantProfile(payload).then(response => {
          store.userStore.setLoader(false);
          if (response?.data?.data?.id) closeModal();
        });
      } else if (type === 'menu') {
        store.userStore.setLoader(true);
        store.menuStore
          .updateHoursOfOperation(menuId, defaultRestaurant?.value, operatingHours)
          .then(response => {
            store.userStore.setLoader(false);
            if (response?.data?.data?.id) closeModal();
          });
      }
    }
  };

  return (
    <div
      className={
        visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-10`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{
          minWidth: screenType.isDesktop ? '65%' : '100%',
          minHeight: '95%',
          maxHeight: '100%',
        }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col" key={weekDays}>
          <button onClick={closeModal}>
            <FontAwesomeIcon
              icon={faTimes}
              color="#ADB4C3"
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <span class="text-sm font-inter-semibold text-dark-purple">Hours</span>

          {weekDays?.map(weekDay => (
            <div
              key={weekDay?.day}
              className="flex flex-col md:flex-row items-start md:items-center mt-6 ml-0 md:ml-16">
              <div className="flex flex-row w-1/3">
                <small className="pr-2 w-auto md:w-1/2 input-label text-sm capitalize">
                  {weekDay?.day}
                </small>

                <div className="md:w-1/2">
                  <ToggleButton
                    toggleState={isOperational(weekDay)}
                    setToggleState={checked => {
                      setToggleState(checked, weekDay);
                    }}
                  />
                </div>

                <small className="px-2 md:w-1/2 input-label text-sm">
                  {isOperational(weekDay) ? 'Open' : 'Closed'}
                </small>
              </div>

              <div className="d-col items-start justify-start content-start">
                {isOperational(weekDay) &&
                  weekDay.hours.map((hour, i, arr) => {
                    const isLastItem = arr.length - 1 === i;
                    const isBelowMaxCount = arr.length < 5;

                    return (
                      <div
                        key={i}
                        className="flex flex-row flex-wrap animate-fade-in-up items-center ml-0 md:ml-5 mb-1">
                        <div className="flex flex-row flex-wrap items-center justify-end">
                          <div className="mr-0 md:mr-2">
                            <small className="input-label">
                              {dateTime.checkAndGetTimezone(timeZone)}
                            </small>

                            <TPicker
                              onChange={time => handleTimeChange(time, weekDay, i, 'start')}
                              value={hour.start}
                              labelName="Start time"
                              disabled={hour?._destroy}
                            />
                          </div>

                          {screenType?.isDesktop && <strong>-</strong>}

                          <div className="ml-2">
                            <small className="input-label">
                              {dateTime.checkAndGetTimezone(timeZone)}
                            </small>

                            <TPicker
                              onChange={time => handleTimeChange(time, weekDay, i, 'end')}
                              value={hour.end}
                              disabled={hour?._destroy}
                            />
                          </div>
                        </div>

                        <div className="d-row justify-end w-full md:w-auto">
                          {isLastItem && isBelowMaxCount && (
                            <button
                              type="button"
                              onClick={() => handleAddClick(weekDay)}
                              className="ml-2 hover:bg-blue-100 p-1 rounded-md">
                              <FontAwesomeIcon icon={faPlus} color={'#6D69D3'} size="md" />
                            </button>
                          )}

                          {!hour._destroy && (
                            <button
                              type="button"
                              className="ml-2 hover:bg-red-100 p-1 rounded-md"
                              onClick={() => handleRemoveClick(weekDay, i)}>
                              <FontAwesomeIcon icon={faTrashAlt} color="#960A0A" size="md" />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}

          <div className="border w-full my-7" />

          <div>
            <div className="d-row justify-between">
              <span class="text-sm font-inter-semibold text-dark-purple">Special Hours</span>

              <button onClick={handleAddSpecialDays} className="btn-purple ml-2 text-xs md:w-1/6">
                Create Event
              </button>
            </div>

            {provider.showTimeError && (
              <small className="input-label text-red-700">
                Please select a time after the current time
              </small>
            )}

            {specialDays?.map((day, index) => (
              <div
                key={index}
                className={`flex flex-col md:flex-row mt-2 ml-0 md:ml-16 ${
                  day.removed && 'opacity-50'
                }`}>
                <div className="d-row w-full">
                  <div className="mr-2 d-col justify-center items-center">
                    <CustomDatePicker
                      setDate={date => {
                        setSpecialDayDate(date, index);
                      }}
                      selectedDate={day.date}
                      transparent
                      placeholder="Select Date"
                      minDate={new Date()}
                    />
                  </div>

                  <ToggleButton
                    toggleState={isOperational(day)}
                    setToggleState={checked => {
                      setSpecialDayAvailability(checked, index);
                    }}
                  />

                  <small className="px-4 input-label text-sm">
                    {isOperational(day) ? 'Open' : 'Close'}
                  </small>

                  <button
                    type="button"
                    className="hover:bg-red-100 rounded-md px-1"
                    onClick={() => handleRemoveSpecialDay(day, index)}>
                    <FontAwesomeIcon icon={faTimes} color={'#960A0A'} size="1x" />
                  </button>
                </div>

                {isOperational(day) && (
                  <div className="d-col w-full items-end">
                    {day.hours
                      ?.filter(
                        hour => hour.start !== default12amTime && hour.end !== default12amTime
                      )
                      ?.map((hour, i, arr) => {
                        const isLastItem = arr.length - 1 === i;
                        const isBelowMaxCount = arr.length < 5;

                        return (
                          <div
                            key={i}
                            className="d-row animate-fade-in-up items-end ml-0 md:ml-5 mt-1">
                            <div
                              className={`flex flex-row ${
                                screenType?.isTabletOrMobile && 'flex-wrap items-center justify-end'
                              }`}>
                              <div className="d-col">
                                <small className="input-label">
                                  {dateTime.checkAndGetTimezone(timeZone)}
                                </small>

                                <TPicker
                                  onChange={time => {
                                    handleSpecialDayTime(time, day, i, 'start', index);
                                  }}
                                  value={hour.start}
                                  disabled={hour?._destroy}
                                />
                              </div>

                              <strong className="mx-2">-</strong>

                              <div className="d-col">
                                <small className="input-label">
                                  {dateTime.checkAndGetTimezone(timeZone)}
                                </small>

                                <TPicker
                                  onChange={time => {
                                    handleSpecialDayTime(time, day, i, 'end', index);
                                  }}
                                  value={hour.end}
                                  disabled={hour?._destroy}
                                />
                              </div>
                            </div>

                            <div className="d-row">
                              {isLastItem && isBelowMaxCount && (
                                <button
                                  type="button"
                                  onClick={() => handleAddSpecialHour(day, index)}
                                  className="ml-2 hover:bg-blue-100 p-1 rounded-md">
                                  <FontAwesomeIcon icon={faPlus} color={'#6D69D3'} size="md" />
                                </button>
                              )}

                              {array.hasMinimumTwo(arr) && (
                                <button
                                  type="button"
                                  className="ml-2 hover:bg-red-100 p-1 rounded-md"
                                  onClick={() => handleRemoveSpecialHour(day, i, index)}>
                                  <FontAwesomeIcon icon={faTrashAlt} color={'#960A0A'} size="md" />
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ))}
          </div>

          {type === 'restaurant' && !array.isEmpty(associatedMenus) && (
            <>
              <div className="border w-full my-7" />

              <div className="d-col">
                <span class="text-sm font-inter-semibold text-dark-purple mb-3">
                  Apply to menus*
                </span>

                <Dropdown
                  label="Select"
                  options={associatedMenus}
                  isMulti
                  selected={provider.menus}
                  onChange={values => {
                    dispatch({ field: 'menusError', value: false });
                    dispatch({ field: 'menus', value: values });
                  }}
                />
              </div>

              <ErrorToast
                visible={provider?.menusError}
                error="Please select the menus to apply."
                transparent
              />
            </>
          )}

          <div className="d-row justify-end mt-7">
            <button className="btn-outline text-xs w-auto md:w-1/6 mr-1" onClick={closeModal}>
              Close
            </button>

            <Button
              className="btn-purple text-xs w-auto md:w-1/6"
              onClick={saveHours}
              label="Save"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoursOfOperations;
