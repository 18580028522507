import React from 'react';
import { NotFound } from 'components/Shared';
import dateTime from 'utils/helpers/dateTime';
import useEmployeeId from 'utils/helpers/user';
import { useHistory } from 'react-router-dom';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import moment from 'moment';
import { useQueryMethods } from 'hooks/useQueryMethods';
import array from 'utils/helpers/array';
import { isODCShipment, isHungerhubBusiness, isDirectCatering } from 'utils/helpers/shipment';

function ScheduleMobile({ data, updateSchedule }) {
  const history = useHistory();
  const { openReportProblemChat } = useFreshChatMethods();
  const { uncateringShipmentRoute, cateringShipmentRoute } = useQueryMethods();
  const { timeZone } = new useEmployeeId();

  const getStatus = shipment_acceptance_status => {
    return (
      <strong
        className={`font-inter-medium text-xs ${
          shipment_acceptance_status ? 'text-green-700' : 'text-red-700'
        }`}>
        {shipment_acceptance_status ? 'Confirmed' : 'Declined'}
      </strong>
    );
  };
  const update = (schedule, status) => {
    updateSchedule(
      schedule?.menu_id,
      schedule?.shipment_ids,
      status,
      moment(schedule?.meal_date).format('dddd, MMMM Do YYYY')
    );
  };
  const navigateToOrders = (schedule, cateringOrder) => {
    let path = '';
    if (cateringOrder)
      path = cateringShipmentRoute({
        source_of_business: schedule.source_of_business,
        shipment_id: schedule?.shipment_ids[0],
      });
    else
      path =
        schedule?.shipment_ids?.length > 1
          ? `/?date=${schedule?.meal_date}`
          : uncateringShipmentRoute({
              shipment_id: schedule?.shipment_ids[0],
            });

    history.push(path);
  };

  const getOrderDeadline = date => {
    if (dateTime.isToday(date)) return dateTime.formatTime(date, timeZone);
    else return dateTime.formatDate(date, 'dddd, MMMM Do hh:mm a', timeZone);
  };

  return (
    <div className="flex flex-col mt-4">
      {array.isPresent(data) &&
        data?.map((schedule, index) => (
          <div
            key={index}
            onClick={() => {
              if (
                schedule?.shipment_acceptance_status === null ||
                schedule?.shipment_acceptance_status
              )
                navigateToOrders(schedule, schedule?.shipment_type == 'odc');
            }}
            className={`${
              index % 2 === 0 ? 'bg-table-row' : 'bg-white'
            } rounded-md w-full shadow-lg p-3 self-center d-col justify-between mt-4 border border-gray-200 cursor-pointer`}>
            <div className="d-row justify-between">
              <div className="d-col">
                <strong className="text-xs font-inter-medium">Menu</strong>
                <small className="text-sm font-inter-regular text-dark-gray">
                  {schedule?.menu}
                </small>
              </div>

              <div className="d-col">
                <strong className="text-xs font-inter-medium text-right">Order Type</strong>
                <small
                  className={`text-sm text-dark-gray font-inter-regular ${
                    schedule?.shipment_type != 'odc' && 'capitalize'
                  }`}>
                  {isODCShipment(schedule?.shipment_type)
                    ? isHungerhubBusiness(schedule?.source_of_business)
                      ? 'hungerhub Catering'
                      : 'Direct Catering'
                    : schedule?.shipment_type}
                </small>
              </div>
            </div>

            <div className="d-col justify-between pt-2">
              <div className="d-col text-left">
                <strong className="text-xs font-inter-medium">Order Date</strong>
                <small className="text-xs text-dim-black font-inter-regular">
                  {moment(schedule?.meal_date).format('dddd, MMMM Do')}
                </small>
              </div>

              <div className="d-col text-left pt-2">
                <strong className="text-xs font-inter-medium">Deadline</strong>
                <small className="text-xs font-inter-regular text-dim-black">
                  {schedule?.shipment_type !== 'odc'
                    ? getOrderDeadline(schedule?.cutoff_time)
                    : `Estimated Pick Up:  ${dateTime.subtractTime(
                        schedule.delivery_time,
                        45,
                        timeZone
                      )} - ${dateTime.subtractTime(schedule.delivery_time, 15, timeZone)}`}
                </small>
              </div>
            </div>

            {schedule?.shipment_type != 'odc' && (
              <div className="d-row justify-between pt-2">
                <div className="d-col text-left">
                  <strong className="text-xs font-inter-medium">Destinations and bags</strong>
                  <div className="d-row mt-1">
                    <div className="border-r-2 border-gray-400">
                      {array.isPresent(schedule?.shipment_details) &&
                        schedule?.shipment_details.map(shipment => (
                          <div className="d-col pr-2">
                            <small className="text-xxs font-inter-regular text-dim-black">
                              {shipment?.destination_code}
                            </small>

                            {schedule?.shipment_type != 'odc' && (
                              <small className="text-xxs text-dim-black font-inter-regular">
                                {shipment?.bags}
                              </small>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="pt-2">
              {schedule?.shipment_acceptance_status === null ? (
                <div className="d-row w-full">
                  <button
                    onClick={e => {
                      e.stopPropagation();

                      update(schedule, true);
                    }}
                    className="btn-purple text-xs w-full bg-green-500 break-normal">
                    Confirm
                  </button>

                  <button
                    onClick={e => {
                      e.stopPropagation();

                      schedule?.shipment_type !== 'odc'
                        ? openReportProblemChat()
                        : isHungerhubBusiness(schedule?.source_of_business)
                        ? openReportProblemChat()
                        : update(schedule, false);
                    }}
                    className="btn-purple text-xs w-full bg-light-red  break-normal">
                    {schedule?.shipment_type !== 'odc'
                      ? 'Report Problem'
                      : isHungerhubBusiness(schedule?.source_of_business)
                      ? 'Report Problem'
                      : 'Decline'}
                  </button>
                </div>
              ) : (
                getStatus(schedule?.shipment_acceptance_status)
              )}

              {schedule?.shipment_acceptance_status &&
                isODCShipment(schedule?.shipment_type) &&
                isDirectCatering(schedule?.source_of_business) && (
                  <div className="d-row">
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        update(schedule, false);
                      }}
                      className="btn-purple text-xxs w-full bg-light-red break-normal">
                      Decline
                    </button>
                  </div>
                )}
            </div>
          </div>
        ))}

      <NotFound data={data} message="No Orders Today" />
    </div>
  );
}

export default ScheduleMobile;
