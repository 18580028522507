import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { useContext, useRef } from 'react';
import Select from 'react-select';
import dateTime from 'utils/helpers/dateTime';

const TPicker = ({ value, onChange, labelName, disabled = false }) => {
  const store = useContext(StoreContext);
  const selectRef = useRef();

  const formattedTime = value && {
    label: dateTime.to12HourFormat(value),
    value: dateTime.to12HourFormat(value),
  };

  return (
    <>
      <Select
        aria-labelledby={labelName ?? 'time-picker'}
        aria-label={labelName ?? 'time-picker'}
        ref={selectRef}
        isSearchable={false}
        className="input-label"
        placeholder={`Time`}
        closeMenuOnSelect
        value={formattedTime}
        isDisabled={disabled}
        onChange={time => {
          onChange(time?.value);
        }}
        options={dateTime.generateTimeOptions()}
      />
    </>
  );
};
export default observer(TPicker);
