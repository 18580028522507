import React, { useContext, useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { NotFound } from 'components/Shared';
import Validation from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import { SpecialInstruction } from 'assets/img';
import { BagDetail } from 'components/Alerts';
import { useExportMethods } from 'hooks/useExportMethods';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import orderHelper from 'utils/helpers/order';
import dateTime from 'utils/helpers/dateTime';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import { useNotificationMethods } from 'hooks/useNotificationMethods';
import array from 'utils/helpers/array';
import { StoreContext } from 'index';
import { toJS } from 'mobx';

function OrderPrepareMobile({
  data,
  withSentStatus,
  currentPath,
  confirmShipment,
  searchText,
  overrideShipmentCapacity,
}) {
  const [bagDetail, showBagDetail] = useState(null);
  const [sortedData, setSortedData] = useState(null);
  const { exportPDF, exportXLS, exportLabel } = useExportMethods();
  const { resetFreshChat, openOrderChat } = useFreshChatMethods();
  const { filterOrdersPrepare, priceWithOptions, getOpacity } = orderHelper;
  const { timeZone } = new useEmployeeId();
  const { resourceId } = useQueryMethods();
  const { acceptDeclineCancellation } = useNotificationMethods();
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { isLoading } = userStore;
  const isRestaurantCapacityReached =
    data?.stopped && (data?.status === 'pending' || data?.status === 'preview');

  useEffect(() => {
    if (searchText === '') setSortedData(data);
  }, [data?.data, sortedData]);

  useMemo(() => {
    if (searchText) {
      setSortedData(prevState => ({
        ...prevState,
        data: filterOrdersPrepare(searchText, data),
      }));
    }
  }, [searchText]);

  return (
    <div className="flex flex-col mt-4">
      {sortedData?.data?.map(
        (
          {
            attributes: {
              status,
              order_items,
              permanent_instructions,
              subtotal,
              grand_total,
              cutlery,
              user_name,
              order_date,
              cutoff,
              hst,
              bag_number,
              cancellation_requested,
              id,
            },
          },
          index
        ) => (
          <div
            key={index}
            className={`odd:bg-table-row rounded-md w-full bg-white shadow-lg p-3 self-center d-col justify-between mt-4 border border-gray-200`}>
            {cancellation_requested && (
              <div className="d-row mb-2 justify-between">
                <small className="text-xxs font-inter-regular text-dark-gray">
                  The user has requested to cancel this order
                </small>

                <div className="d-row justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      acceptDeclineCancellation(id, true);
                    }}
                    className="bg-green-600 btn-purple p-1.5 rounded-md text-xs">
                    Approve
                  </button>

                  <button
                    onClick={() => acceptDeclineCancellation(id, false)}
                    className="bg-red-700 btn-purple p-1.5 rounded-md text-xs">
                    Decline
                  </button>
                </div>
              </div>
            )}

            <div className="d-row justify-between">
              {status === 'cancelled' ? (
                <small className="text-red-500 font-inter-regular text-center">Bag removed</small>
              ) : (
                <strong className="text-xs font-inter-medium">
                  {bag_number} - {user_name} - {sortedData?.destination_code}
                </strong>
              )}

              <button
                className="btn-outline font-inter-regular text-xxs p-2 shadow-none self-start"
                onClick={e => {
                  e.preventDefault();
                  resetFreshChat();
                  showBagDetail({
                    status,
                    order_items,
                    permanent_instructions,
                    subtotal,
                    grand_total,
                    cutlery,
                    user_name,
                    order_date,
                    cutoff,
                    bag_number,
                    hst,
                  });
                }}>
                View Details
              </button>
            </div>

            <div className={`${getOpacity(status)} d-row`}>
              <strong className="text-xs font-inter-medium pr-2">Items:</strong>
              <strong className="text-xs font-inter-medium">{order_items?.length}</strong>
            </div>

            <div className="d-row justify-between pt-2">
              <div className={`py-1 break-words flex-row align-middle ${getOpacity(status)}`}>
                <div className="d-col">
                  {permanent_instructions ? (
                    <div className="d-row items-start">
                      <FontAwesomeIcon icon={faExclamationTriangle} color={'#FF9966'} size="xs" />
                      <small className="text-xs text-dark-gray font-inter-regular pl-1">
                        <div title={permanent_instructions}>{permanent_instructions}</div>
                      </small>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>

              {cutlery ? (
                <FontAwesomeIcon icon={faUtensils} color={'#6D69D3'} size="sm" />
              ) : (
                <div />
              )}
            </div>

            <div className="d-row justify-between pt-2">
              <table className="w-full table-fixed">
                <tbody>
                  {order_items?.map(({ attributes }, index) => (
                    <tr key={index}>
                      <td>
                        <table className="w-full table-fixed">
                          <tbody>
                            <tr>
                              <td className="text-left align-top py-0" style={{ width: '28%' }}>
                                <p className="overflow-ellipsis overflow-hidden text-xs d-row items-start text-dark-gray font-inter-regular">
                                  {attributes.category}
                                </p>
                              </td>

                              <td style={{ width: '58%' }}>
                                <p className="overflow-ellipsis overflow-hidden text-xs d-row items-start text-dark-gray font-inter-regular">
                                  {attributes?.special_instructions && (
                                    <img
                                      src={SpecialInstruction}
                                      className="object-contain h-6 w-6 pr-2"
                                    />
                                  )}
                                  {attributes?.menu_item?.title}
                                </p>
                                {attributes?.special_instructions && (
                                  <p className="overflow-ellipsis overflow-hidden text-xs lg:text-xs text-pink-500 font-inter-regular">
                                    {attributes?.special_instructions}
                                  </p>
                                )}
                              </td>

                              <td
                                className="text-right align-top text-dark-gray"
                                style={{ width: '14%' }}>
                                <small className="font-inter-regular text-xs flex flex-col">
                                  {Validation.priceFormatter(attributes?.price)}
                                </small>
                              </td>
                            </tr>

                            <tr className="animate-fade-in-down">
                              <td colSpan="4">
                                <table className="w-full table-fixed">
                                  {attributes?.order_item_options?.map(({ attributes }, index) => (
                                    <tr key={index}>
                                      <td style={{ width: '28%' }} />

                                      <td style={{ width: '58%' }}>
                                        <div className="d-col">
                                          <small className="font-inter-regular break-normal text-gray-500 text-xs pt-2">
                                            {attributes?.option_category_name}:{' '}
                                            {attributes?.option_name}
                                          </small>
                                        </div>
                                      </td>

                                      <td className="text-right justify-end flex">
                                        <small className="self-end font-inter-regular text-xs text-gray-500 pt-2">
                                          {Validation.priceFormatter(attributes?.price)}
                                        </small>
                                      </td>
                                    </tr>
                                  ))}
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}

      <BagDetail
        closeModal={() => {
          showBagDetail(null);
          resetFreshChat();
        }}
        orderData={bagDetail}
        currentPath={currentPath}
        destinationCode={sortedData?.destination_code}
        cutoff={sortedData?.cutoff}
      />

      <NotFound data={data?.data} message="No Orders Today" />

      <div className="d-col md:flex-row justify-between w-full bg-white my-4 items-center">
        <div className="d-row w-full">
          <button
            onClick={() =>
              exportLabel(sortedData?.destination_code, sortedData?.meal_date, resourceId())
            }
            disabled={array.isEmpty(sortedData?.data)}
            className={`btn-outline text-xs w-1/2 ${
              array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
            }`}>
            <small>Export Labels</small>
          </button>

          <button
            onClick={() =>
              exportPDF(sortedData?.destination_code, sortedData?.meal_date, resourceId())
            }
            disabled={array.isEmpty(sortedData?.data)}
            className={`btn-outline text-xs w-1/2 mx-2 ${
              array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
            }`}>
            <small>Export PDF</small>
          </button>

          <button
            onClick={() =>
              exportXLS(sortedData?.destination_code, sortedData?.meal_date, resourceId())
            }
            disabled={array.isEmpty(sortedData?.data)}
            className={`btn-outline text-xs w-1/2 ${
              array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
            }`}>
            <small>Export XLS</small>
          </button>
        </div>

        <div className="w-full md:w-auto">
          {withSentStatus && !isLoading && (
            <button
              onClick={() => confirmShipment()}
              className="btn-purple w-full bg-red-600 text-xs mt-2">
              <small>Confirm</small>
            </button>
          )}

          {isRestaurantCapacityReached && (
            <button
              onClick={overrideShipmentCapacity}
              className="btn-purple w-full text-xs md:w-auto mt-2 md:mt-0">
              <small>Accept additional orders</small>
            </button>
          )}

          <button
            disabled={currentPath?.help}
            onClick={() =>
              openOrderChat(
                sortedData?.destination_code,
                dateTime.formatTime(sortedData?.cutoff, timeZone)
              )
            }
            className={`btn-purple text-xs mt-2 w-full ${currentPath?.help && 'opacity-50'}`}>
            <small>Help</small>
          </button>
        </div>
      </div>
    </div>
  );
}

export default observer(OrderPrepareMobile);
