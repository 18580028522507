import React, { useState, useMemo, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { BagDetail } from 'components/Alerts';
import { SortButton } from 'components/Shared';
import Screen from 'utils/helpers/ScreenSize';
import Validation from 'utils/helpers/validation';
import { SpecialInstruction } from 'assets/img';
import { NotFound } from 'components/Shared';
import array from 'utils/helpers/array';
import { useExportMethods } from 'hooks/useExportMethods';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import orderHelper from 'utils/helpers/order';
import { Tooltip } from 'components/Shared';
import { useQueryMethods } from 'hooks/useQueryMethods';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

function CateringOrderTable({
  data,
  withSentStatus,
  currentPath,
  searchText,
  confirmShipment,
  confirmShipmentFirst,
}) {
  const [currentSort, setCurrentSort] = useState('asc');
  const [bagDetail, showBagDetail] = useState(null);
  const screenType = Screen.ScreenType();
  const [sortedData, setSortedData] = useState(null);
  const { exportPDF, exportXLS, exportLabel } = useExportMethods();
  const { resourceId } = useQueryMethods();
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { isLoading } = userStore;
  const { resetFreshChat, openOrderChat } = useFreshChatMethods();
  const {
    filterOrdersPrepare,
    simplePriceWithOptions,
    priceWithOptions,
    getOpacity,
    getLabel,
    showExtraLabel,
  } = orderHelper;

  useEffect(() => {
    if (searchText === '') setSortedData(data);
  }, [data?.data, sortedData]);

  useMemo(() => {
    if (searchText) {
      setSortedData(prevState => ({
        ...prevState,
        data: filterOrdersPrepare(searchText, data),
      }));
    }
  }, [searchText]);

  const sortByColumnName = column => {
    setSortedData(
      array.sortTableColumnWithAttributes(
        sortedData?.data,
        column,
        currentSort === 'asc' ? 'desc' : 'asc'
      )
    );
    currentSort === 'asc' ? setCurrentSort('desc') : setCurrentSort('asc');
  };

  return (
    <div className="d-col mt-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full table-fixed">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="t-head w-1/12 text-center">
                    <small className="t-row">Quantity</small>
                  </th>

                  {/* {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head w-3/12">
                      <div className="d-row pt-1">
                        <small className="t-row">Permanent Instructions</small>
                        <Tooltip
                          tooltipText="Please pay careful attention to allergies and contact support with any issues."
                          id="permanent-instructions"
                        />
                      </div>
                    </th>
                  )} */}

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head w-1/6">
                      <small className="t-row">Category</small>
                    </th>
                  )}

                  <th scope="col" className="t-head w-2/12">
                    <small className="t-row">Item</small>
                  </th>

                  <th scope="col" className="t-head w-3/12">
                    <small className="t-row">Label</small>
                  </th>

                  <th scope="col" className="t-head w-1/12 text-left">
                    <small className="t-row">Food Total</small>
                  </th>

                  {screenType.isTabletOrDesktop && (
                    <th className="t-head text-center w-1/12">
                      <div className={`${screenType.isDesktop && 'd-row pt-1'}`}>
                        <Tooltip
                          tooltipText="Only include cutlery if the user has requested it, otherwise please do not include cutlery."
                          id="cutlery-instructions"
                        />
                        <small className="t-row">Cutlery</small>
                        {searchText === '' && (
                          <SortButton onClick={() => sortByColumnName('cutlery')} />
                        )}
                      </div>
                    </th>
                  )}
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {sortedData?.data?.map(
                  (
                    {
                      attributes: {
                        status,
                        order_items,
                        permanent_instructions,
                        subtotal,
                        cutlery,
                        user_name,
                        order_date,
                        cutoff,
                        hst,
                        bag_number,
                      },
                    },
                    index
                  ) => (
                    <tr
                      key={index}
                      className="odd:bg-table-row hover:bg-blue-50 cursor-pointer animate-fade-in-down"
                      style={{
                        borderWidth: status === 'sent' ? 1 : 0,
                        borderColor: 'red',
                      }}>
                      <td
                        colSpan="6"
                        className={`py-3 text-dark-gray animate-fade-in-down ${getOpacity(
                          status
                        )}`}>
                        <table className="w-full table-fixed">
                          <tbody>
                            {order_items?.map(({ attributes }, orderItemIndex) => (
                              <tr key={orderItemIndex}>
                                <td>
                                  <table className="w-full table-fixed">
                                    <tbody>
                                      <tr key={orderItemIndex}>
                                        <td className="text-center align-top w-1/12">
                                          <small className="font-inter-regular text-xs text-black lg:text-sm">
                                            {attributes?.quantity}
                                          </small>
                                        </td>

                                        <td className="text-left align-top w-1/6">
                                          <small className="font-inter-regular text-xs text-black lg:text-sm">
                                            {attributes.category}
                                          </small>
                                        </td>

                                        <td className="align-top w-2/12">
                                          <div className="d-col pb-4">
                                            <p className="overflow-ellipsis overflow-hidden text-xs d-row lg:text-sm text-black font-inter-regular">
                                              {attributes?.special_instructions && (
                                                <img
                                                  src={SpecialInstruction}
                                                  className="object-contain h-6 w-6 pr-2"
                                                />
                                              )}
                                              {attributes?.menu_item?.title}
                                            </p>

                                            {attributes?.special_instructions && (
                                              <p className="overflow-ellipsis overflow-hidden text-xs lg:text-xs text-pink-500 font-inter-regular">
                                                {attributes?.special_instructions}
                                              </p>
                                            )}

                                            {attributes?.order_item_options?.map(
                                              ({ attributes }, index) => (
                                                <div className="d-col" key={`${index}optionName`}>
                                                  <small className="font-inter-regular break-normal text-xs pt-2">
                                                    {attributes?.option_category_name}:{' '}
                                                    {attributes?.option_name}
                                                  </small>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </td>

                                        <td className="text-left align-top text-black w-3/12 pr-4 pb-4">
                                          <div className="flex flex-col">
                                            {attributes?.notes !== '' &&
                                            attributes?.notes?.split('#@').length >= 1 ? (
                                              <>
                                                <p className="overflow-ellipsis text-black overflow-hidden mb-2 text-xs lg:text-xs font-inter-regular">
                                                  {`${attributes?.quantity > 1 ? '1 - ' : ''} ${
                                                    attributes?.quantity
                                                  } - ${attributes?.notes?.split('#@')} - ${
                                                    sortedData.destination_code
                                                  }`}
                                                </p>
                                              </>
                                            ) : (
                                              <div className="flex flex-col">
                                                {showExtraLabel(attributes, sortedData)}
                                              </div>
                                            )}
                                          </div>
                                        </td>

                                        <td className="text-left align-top w-1/12">
                                          <div className="d-col">
                                            <small className="font-inter-regular text-black text-xs lg:text-sm flex flex-col">
                                              {Validation.priceFormatter(
                                                attributes?.price * attributes?.quantity
                                              )}
                                            </small>

                                            {attributes?.order_item_options?.map(
                                              (option, index) => (
                                                <div className="d-col" key={`${index}optionPrice`}>
                                                  <span className="font-inter-regular text-xs flex flex-col pt-2">
                                                    {Validation.priceFormatter(
                                                      option?.attributes?.price *
                                                        attributes?.quantity
                                                    )}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </td>

                                        {screenType.isTabletOrDesktop && (
                                          <td className="align-top text-center w-1/12">
                                            {cutlery ? (
                                              <div className="flex" style={{ paddingLeft: '30px' }}>
                                                <span className="mr-2 font-inter-regular text-xs text-black lg:text-sm">
                                                  {attributes?.quantity}x
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={faUtensils}
                                                  color={'#6D69D3'}
                                                  size="sm"
                                                />
                                              </div>
                                            ) : (
                                              <div />
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <NotFound data={data?.data} message="No Bags Found" />
          </div>

          <BagDetail
            closeModal={() => {
              showBagDetail(null);
              resetFreshChat();
            }}
            orderData={bagDetail}
            currentPath={currentPath}
            destinationCode={sortedData?.destination_code}
            cutoff={sortedData?.cutoff}
          />

          <div className="d-col md:flex-row justify-between w-100 bg-white my-4 items-center">
            <div className="d-row">
              <button
                disabled={array.isEmpty(sortedData?.data)}
                className={`btn-outline ${array.isEmpty(sortedData?.data) && 'cursor-not-allowed'}`}
                title="Export a prefilled PDF of order labels to attach to each bag. Note: Do not prepare orders using labels as they do not include user options"
                onClick={() =>
                  exportLabel(sortedData?.destination_code, sortedData?.meal_date, resourceId())
                }>
                <small>Export Labels</small>
              </button>
              <button
                title="Export a prefilled PDF of order labels to attach to each bag. Note: Do not prepare orders using labels as they do not include user options"
                onClick={() =>
                  exportPDF(sortedData?.destination_code, sortedData?.meal_date, resourceId())
                }
                disabled={array.isEmpty(sortedData?.data)}
                className={`btn-outline mx-2 ${
                  array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
                }`}>
                <small>Export PDF</small>
              </button>
              <button
                title="Export an XLS containing all order items, options & label details"
                onClick={() =>
                  exportXLS(sortedData?.destination_code, sortedData?.meal_date, resourceId())
                }
                disabled={array.isEmpty(sortedData?.data)}
                className={`btn-outline mx-2 ${
                  array.isEmpty(sortedData?.data) && 'cursor-not-allowed'
                }`}>
                <small>Export XLS</small>
              </button>
            </div>

            <div className="w-full md:w-auto">
              {sortedData?.data &&
              sortedData?.data[0]?.attributes?.shipment_acceptance_status === null ? (
                <button
                  onClick={confirmShipmentFirst}
                  className="btn-purple w-full md:w-auto bg-red-600 px-14 lg:px-24 mt-2 md:mt-0">
                  <small>Confirm Order</small>
                </button>
              ) : (
                <>
                  {withSentStatus && !isLoading && (
                    <button
                      onClick={() => confirmShipment()}
                      className="btn-purple w-full md:w-auto bg-red-600 px-14 lg:px-24 mt-2 md:mt-0">
                      <small>Confirm</small>
                    </button>
                  )}
                </>
              )}

              <button
                disabled={currentPath?.help}
                onClick={openOrderChat}
                className={`btn-purple px-10 ${currentPath?.help && 'opacity-50'}`}>
                <small>Help</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(CateringOrderTable);
