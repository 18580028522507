import React, { useContext } from 'react';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import { createBrowserHistory } from 'history';
import { StoreContext } from 'index';
import dateTime from 'utils/helpers/dateTime';
import useEmployeeId from 'utils/helpers/user';
import Validation from 'utils/helpers/validation';
import { useHistory } from 'react-router-dom';
import order from 'utils/helpers/order';
import moment from 'moment-timezone';

function ActionCableAlert(props) {
  const store = useContext(StoreContext);
  let history = useHistory();
  const userStore = toJS(store.userStore);
  const orderStore = toJS(store.orderStore);
  let { currentPath, actionCablePayload } = orderStore;
  const notificationStore = toJS(store.notificationStore);
  const { unreadNotifications } = notificationStore;
  const { employeeId, timeZone } = new useEmployeeId();

  const getBorderLeft = () => {
    switch (actionCablePayload?.notification_for) {
      case 'new_order':
        return 'border-green-700';
      case 'cancel_order':
        return 'border-red-700';
      case 'bag_added':
        return 'border-orange-brown';
      case 'order_preview': //lock order/shipment locked
        return 'border-blue-200';
      case 'all_shipments_published': //lock order/shipment locked
        return 'border-blue-200';
      case 'hungerhub_catering_order_added':
      case 'hungerhub_catering_order_cancellation':
      case 'direct_catering_order_cancellation':
      case 'hungerhub_catering_order_confirmation':
      case 'direct_catering_order_confirmation':
      case 'new_catering_order':
        return 'border-pink-600';
      default:
        return 'border-light-purple';
    }
  };

  const getButtonBackground = () => {
    switch (actionCablePayload?.notification_for) {
      case 'new_order':
        return 'bg-green-700';
      case 'cancel_order':
        return 'bg-red-700';
      case 'bag_added':
        return 'bg-orange-brown';
      case 'order_preview': //lock order/shipment locked
        return 'bg-blue-400';
      case 'order_finalize':
        return 'bg-purple-600';
      case 'all_shipments_published':
        return 'bg-purple-500';
      //Catering orderss
      case 'hungerhub_catering_order_added':
      case 'hungerhub_catering_order_cancellation':
      case 'direct_catering_order_cancellation':
      case 'hungerhub_catering_order_confirmation':
      case 'direct_catering_order_confirmation':
      case 'new_catering_order':
        return 'bg-pink-600';
      default:
        return 'bg-light-purple';
    }
  };

  const headingText = () => {
    switch (actionCablePayload?.notification_for) {
      case 'new_order':
        return 'New Order';
      case 'cancel_order':
        return 'Order Canceled';
      case 'bag_added':
        return 'Bag Removed';
      case 'order_preview': //lock order/shipment locked
        return 'Order Alert';
      case 'order_finalize': //to confirm an order
        return 'Order Finalized';
      case 'cancellation_request':
        return 'Cancellation Request';
      case 'all_shipments_published':
        return 'Uncatering schedule available for review';
      case 'additional_shipment_added':
        return 'Uncatering order added';
      case 'unconfirmed_shipment_reminder':
        return 'Unconfirmed schedule reminder';
      case 'shipment_removed':
        return 'Uncatering order removed';
      case 'expecte_bags_changed':
        return 'Updated expected bag count';
      case 'hungerhub_catering_order_added':
        return 'hungerhub catering order added';
      case 'new_catering_order':
        return 'New catering order';
      case 'hungerhub_catering_order_cancellation':
        return 'hungerhub Catering Order Cancelled';
      case 'direct_catering_order_cancellation':
        return 'Direct catering order Cancelled';
      case 'hungerhub_catering_order_confirmation':
        return 'Please confirm hungerhub catering order';
      case 'direct_catering_order_confirmation':
        return 'Please confirm direct catering order';
      case 'direct_catering_order_added':
        return 'Direct catering order added';

      default:
        return 'Order Alert';
    }
  };

  const subHeadingText = () => {
    let attributes = actionCablePayload?.data;
    switch (actionCablePayload?.notification_for) {
      //For the uncatering orders
      case 'new_order':
        return `A bag has been added to your order for ${attributes?.destination_code} on ${
          attributes?.date
        } - ${dateTime.formatTime(attributes?.cutoff, timeZone)}.`;
      case 'cancel_order':
        return `A bag has been removed from your order for ${attributes?.destination_code} on ${
          attributes?.date
        } - ${dateTime.formatTime(attributes?.cutoff, timeZone)}.`;
      case 'bag_added':
        return `Additional bag is added to ${attributes?.destination_code} on ${
          attributes?.date
        } - ${dateTime.formatTime(attributes?.cutoff, timeZone)}.`;
      case 'order_preview':
        return `Your order for ${attributes?.destination_code} on ${
          attributes?.date
        } - ${dateTime.formatTime(attributes?.cutoff, timeZone)} has been locked. \n\n\n
         Users can no longer make changes to this order but can add additional bags`;
      case 'order_finalize':
        return 'Navigate to order details page to confirm.';
      case 'cancellation_request':
        return `There is a cancellation request for an order for  ${
          attributes?.destination_code
        } on ${attributes?.date} - ${dateTime.formatTime(attributes?.cutoff, timeZone)}.`;
      //For the meal management notifications
      case 'all_shipments_published':
        return 'Your Uncatering schedule for next week is available for review, please confirm your schedule as soon as possible.';
      case 'additional_shipment_added':
        return 'An additional Uncatering order has been added to your schedule, please confirm the order as soon as possible.';
      case 'unconfirmed_shipment_reminder':
        return 'You currently have unconfirmed Uncatering orders for the upcoming week, please confirm the orders as soon as possible.';
      case 'shipment_removed':
        return `The Uncatering order on ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )} for ${attributes?.destination_code} has been cancelled by the ${
          attributes?.cancelled_by
        }. We have therefore updated your schedule to reflect these changes. We apologize for any inconvenience.`;
      case 'expecte_bags_changed':
        return `The expected bag count for your Uncatering order on ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )} for ${
          attributes?.destination_code
        } has been updated based on new information from the client. Please review your updated schedule as soon as possible.`;
      //For the direct catering and hungerhub catering orders
      case 'hungerhub_catering_order_added':
        return `A new hungerhub catering order has been added for ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )}, please confirm the order as soon as possible.`;
      case 'new_catering_order':
        return `A new catering order has been added for ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )}, please confirm the order as soon as possible.`;
      case 'hungerhub_catering_order_cancellation':
        return `The hungerhub catering order previously requested for ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )} for destination code ${attributes?.destination_code} has been cancelled by the ${
          attributes?.cancelled_by
        }. Your schedule has been updated to reflect this change.`;
      case 'direct_catering_order_cancellation':
        return `The direct catering order previously requested for ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )} for destination code ${attributes?.destination_code} has been cancelled by the ${
          attributes?.cancelled_by
        }. Your schedule has been updated to reflect this change.`;
      case 'hungerhub_catering_order_confirmation':
        return `Today's hungerhub catering order for ${
          attributes?.destination_code
        } will be picked up at ${dateTime.formatTime(
          attributes?.pickup_time,
          timeZone
        )}. Please confirm the order as soon as possible. `;
      case 'direct_catering_order_confirmation':
        return `Today's direct catering order for ${
          attributes?.destination_code
        } will be picked up at ${dateTime.formatTime(
          attributes?.pickup_time,
          timeZone
        )}. Please confirm the order as soon as possible. `;
      case 'direct_catering_order_added':
        return `A new direct catering order has been added for ${dateTime.formatDateWithoutTz(
          attributes?.date,
          'MMMM D, yyyy'
        )}, please confirm the order as soon as possible.`;

      default:
        return 'An order update';
    }
  };

  const navigateToOrders = () => {
    let type = actionCablePayload?.notification_for;
    let navigateTo = '';
    if (
      type === 'shipment_removed' ||
      type === 'unconfirmed_shipment_reminder' ||
      type === 'expecte_bags_changed'
    ) {
      navigateTo = `/schedule?start_date=${actionCablePayload?.data?.date}`;
    } else if (type === 'additional_shipment_added' || type === 'all_shipments_published') {
      navigateTo = `/schedule?start_date=${actionCablePayload?.data?.start_date}`;
    } else if (
      type === 'hungerhub_catering_order_added' ||
      type === 'new_catering_order' ||
      type === 'hungerhub_catering_order_confirmation' ||
      type === 'direct_catering_order_confirmation'
    ) {
      navigateTo = `/catering-order/${
        actionCablePayload?.data?.shipment_id
      }?source_of_business=${order.getBusinessSource(type)}`;
    } else if (
      type === 'hungerhub_catering_order_cancellation' ||
      type === 'direct_catering_order_cancellation'
    ) {
      navigateTo = `/order-summary?date=${actionCablePayload?.data?.date}`;
    } else {
      navigateTo = `/uncatering-order/${actionCablePayload?.data?.shipment_id}?status=prepare`;
    }

    store.orderStore.setActionCablePayload(null);
    clearNotification();
    history.push(navigateTo);
  };

  const clearNotification = () => {
    let payload = { notification_ids: [actionCablePayload?.notification_id] };
    store.notificationStore
      .clearNotifications(actionCablePayload?.notification_id, payload, {
        employee_id: employeeId,
      })
      .then(response => {
        store.notificationStore.getNotifications(
          {
            employee_id: employeeId,
            date: dateTime.formatDate(new Date(), 'YYYY-MM-DD', timeZone),
            per_page: 5,
            page: 1,
            status: 'unread',
          },
          true
        );
      });
  };

  let navigateButton = (
    <button onClick={navigateToOrders} className={`${getButtonBackground()} btn-orange mb-3 mt-11`}>
      <small className="text-sm font-inter-medium">Okay</small>
    </button>
  );

  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '400px' }}
        aria-labelledby="modal-headline">
        {unreadNotifications &&
        unreadNotifications[dateTime.formatDate(new Date(), 'YYYY-MM-DD', timeZone)]?.length > 1 ? (
          <div className={`flex bg-white p-6 border-l-8  flex-col border-red-600`}>
            <h5 className="font-inter-medium">Multiple Order Alerts</h5>
            <small className="text-dim-black font-inter-regular text-sm py-3 break-words">
              You have multiple pending notifications. Please open the notification tray in the top
              right hand corner to view all.
            </small>
            <button
              onClick={() => {
                store.orderStore.setActionCablePayload(null);
              }}
              className={`bg-red-600 btn-orange mb-3 mt-11`}>
              <small className="text-sm font-inter-medium">Okay</small>
            </button>
          </div>
        ) : actionCablePayload?.notification_for === 'order_finalize' ? (
          <div className={`flex bg-white p-6 flex-col`}>
            <h5 className="font-inter-medium">{headingText()}</h5>
            <small className="text-dim-black font-inter-regular text-sm py-3 break-words">
              {subHeadingText()}
            </small>

            <div className="border d-row rounded-md">
              <div className=" d-col w-1/2">
                <small className="border-b p-2 input-label">Destination Code</small>
                <small className="border-b p-2 input-label">No. of Bags</small>
                <small className="border-b p-2 input-label">Items</small>
                <small className="border-b p-2 input-label">Food Total</small>
                <small className="border-b p-2 input-label">Pickup Time</small>
              </div>

              <div className="w-1/2 d-col border-l-2">
                <small className="border-b p-2 input-label break-words">
                  {actionCablePayload?.data?.destination_code}
                </small>
                <small className="border-b p-2 input-label">
                  {actionCablePayload?.data?.bags_count}
                </small>
                <small className="border-b p-2 input-label">
                  {actionCablePayload?.data?.items}
                </small>
                <small className="border-b p-2 input-label">
                  {Validation.priceFormatter(actionCablePayload?.data?.food_total)}
                </small>
                <small className="border-b p-2 input-label">
                  {actionCablePayload?.data.pickup_time
                    ? dateTime.formatTime(actionCablePayload?.data.pickup_time, timeZone)
                    : 'Not Available'}
                </small>
              </div>
            </div>
            {navigateButton}
          </div>
        ) : actionCablePayload?.notification_for === 'cancellation_request' ? (
          <div className="flex bg-white p-6 flex-col">
            <h5 className="font-inter-medium">{headingText()}</h5>
            <small className="text-dim-black font-inter-regular text-sm py-3 break-words">
              {subHeadingText()}
            </small>

            {navigateButton}
          </div>
        ) : (
          <div className={`flex bg-white p-6 border-l-8  flex-col ${getBorderLeft()}`}>
            <h5 className="font-inter-medium">{headingText()}</h5>
            <small className="text-dim-black font-inter-regular text-sm py-3 break-words">
              {subHeadingText()}
            </small>

            {navigateButton}
          </div>
        )}
      </div>
    </div>
  );
}

export default ActionCableAlert;
